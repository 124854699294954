import React, { useState } from "react";
import "./AdditionalBaggage.css"
import AdditionalBaggageCard from "../../components/AdditionalBaggageCard/AdditionalBaggageCard"; 
import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import FetchData from "../../components/Api/FetchData";
import Loader from "../../components/common/Loader";
import { useSelector } from "react-redux";
import { getDomainName } from "../../components/Api/GetBaseUrl";

function AdditionalBaggage() { 
  const {translations,lang}=useSelector((state)=>state.languageReducer)
  const navigate = useNavigate();
  const { CategorieId } = useParams();
  const [serviceDetails, setServiceDetails] = useState([]);
  const [loader,setLoader]=useState(true);
  const token = Cookies.get('token');
  const domainName=getDomainName();
  const fetchServiceDetails = async () => {
    const data = await FetchData(`/services/${CategorieId}`, token, lang);
    if(data.status===200){
      setServiceDetails(data.data);
      console.log('asdsaasdas',data.data)
    }
    else{
      navigate('/404')
    }      
    setLoader(false);
};
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchServiceDetails();
    
  }, []);

 if(loader){
  return(
    <Loader/>
  )  
}
  return (
    <div>
         <div className=''>  
    <div>
    <div className='Cover'>    
    
     <div className='container '>
    
   
    
   
    <div className="d-flex justify-content-center align-items-start" >
    <div>
        <div className="container-fluid  my-5">
         <div className="d-flex flex-column flex-md-row gap-3 justify-content-center text-center text-md-start align-items-center">
         {serviceDetails[0]?.category?.image&&(
          <img className='' style={{height:'150px',borderRadius:'20px'}} src={`${domainName}/${serviceDetails[0]?.category.image}`}/> 
            )}
            <div className="d-flex flex-column gap-2 ">
            <h1 className='text-white fw-bold '> {serviceDetails[0]?.category.title}</h1> 
           
           <div 
                      dangerouslySetInnerHTML={{
                        __html: serviceDetails[0]?.category?.description,
                      }}
                     style={{color:'#D6D9E4',fontWeight:'bold',fontSize:'18px'}}
                    ></div>
            </div>
         </div>
          <div
            style={{marginTop: "20px" }}
            className="container text-start "
          >
             
            {/* <p className="textd" style={{ width: "80%" }}>
              If your luggage weighs more than the allowed limit, you will be
              required to pay an excess baggage fee. You have two options to pay
              this fee:
            </p> */}
            {/* <p className="text">
              1. You can pay online and take advantage of our best rates, which
              are up to 40% cheaper than the airport rates. This option is
              available up to 4 hours before the scheduled departure time of
              your flight.
            </p>   */}

            {/* <p className="text">2. You can also pay for your excess baggage at the airport when you check-in.</p> 
            <p className="textd">Please note that the maximum weight allowed for any single piece of excess baggage is 32 kg.</p>  */}
           <div className="continar text-center">
           <div className="row ">
        
             
               <AdditionalBaggageCard services={serviceDetails}/>
             
          
           </div>
           </div>
          
          
          
          </div> 
          
        </div>
      </div>
   </div>
     </div>
    </div> 
   
    </div>
   
    </div>
    
    </div>
  );
}

export default AdditionalBaggage;
