import React, { useEffect, useState } from 'react'
import Nav from '../Nav/Nav'
import Footer from '../Footer/Footer'
import FetchData from '../Api/FetchData'
import Loader from './Loader'
import { useDispatch, useSelector } from 'react-redux'
import { changeLanguage } from '../../redux/languageSlice'

const Warp = ({children}) => {
    const {translations,lang}=useSelector((state)=>state.languageReducer)
    const [load,setLoad]=useState(false)
    const [setting,setSetting]=useState({})
    const dispatch=useDispatch();
    useEffect(()=>{
      let language=localStorage.getItem('lang');
      if(language){
        dispatch(changeLanguage(language))
      }
    },[])
    useEffect(()=>{
      setLoad(true)
      FetchData('/website-settings','',lang).then((data)=>{
        if(data.status){
          setSetting(data.data)
          setLoad(false)
        }

      }).catch(()=>{
          // setLoad(false)
      })

    },[lang])
    if(load){
      return(
        <Loader/>
      )  
    }
  return (
    <>
     <Nav logo={setting.header_logo}/> 
        <div style={{minHeight:'70vh'}}>
        {children}
        </div>
    <Footer setting={setting}/> 
    </>
  )
}

export default Warp