
const GetBaseUrl = () => {
  const baseUrl = `https://nesma-backend.inticafe.com/api/`;
  return baseUrl;
};
export default GetBaseUrl;

export const getDomainName=()=>{
  const baseUrl = `https://nesma-backend.inticafe.com`;
  return baseUrl;
}
