import React, { useState } from "react";
import ChooseMeal from "../../components/ChooseMeal/ChooseMeal"; 
import "./MealsOnBoard.css"; 
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import FetchData from "../../components/Api/FetchData";

const MealsOnBoard = () => {
  const { CategorieId } = useParams();
  const [serviceDetails, setServiceDetails] = useState(null);
  const [loader,setLoader]=useState(true); 
  const token = Cookies.get('token');
  const locale = 'en';
  const fetchServiceDetails = async () => {
    const data = await FetchData(`/services/${CategorieId}`, token, locale);
    if (!data.error) {
        setServiceDetails(data);
        console.log('dataasdasd',data)
    } else {
        console.error('Failed to fetch service details:', data.error);
    }
    setLoader(false);
};
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchServiceDetails();
    
  }, []);
  if(loader){
    return <div>Loading...</div>
   }
  return (
    <div>
      <div className="container-fluid my-5">
        <div
          className="row"
          style={{ padding: "20px", backgroundColor: "#D8DCE3" }}
        >
          <h3 className="titleCard" style={{ marginLeft: "70px" }}>
            {serviceDetails[0]?.category.title}
          </h3>
        </div>
        <div
          style={{ marginTop: "50px" }}
          className="container text-start layout-wrapper"
        >
          <div className="container-fluid">
          
           <div
            style={{  marginTop: "50px" }}
            className="container text-start layout-wrapper"
          >
             
             <div 
                        dangerouslySetInnerHTML={{
                          __html: serviceDetails[0]?.category.description,
                        }}
                       
                      ></div>

           
          
          </div>
          
        </div> 
          
          <ChooseMeal serviceDetails={serviceDetails}/>
        </div> 
       
      </div>
      
    </div>
  );
};

export default MealsOnBoard;
