import React, { useEffect, useState } from "react";
import FetchData from "../../components/Api/FetchData"; // Ensure this is correctly imported
import { getDomainName } from "../../components/Api/GetBaseUrl";
import Cookies from "js-cookie"; // Make sure this is used if necessary, currently it's imported but not used
import Loader from "../../components/common/Loader";
import { PageContent } from "../../components/common/PageContent";

export const Terms = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const Url = "/terms"; // Ensure this is your actual endpoint
      const token = localStorage.getItem("token"); // Ensure you are managing tokens securely
      const locale = "en"; // Locale can be dynamic based on your requirements

      setLoading(true);
      const result = await FetchData(Url, token, locale);
      if (result.status) {
        setData(result.data);
      } 
      setLoading(false);
    };

    fetchData();
  }, []); // The dependency array is empty, ensuring this runs only once

  // Get domain name from your utility function
  const domainName = getDomainName();

  // Only try to create imagePath if data is not null
  const imagePath = data ? `${domainName}/${data.image}` : '';

  return (
    <>
      {loading && <div><Loader/> </div>}
      {!loading && data && (
        <section>
        <PageContent title={data.title} page={data.page} description={data.description} image={data.image}/>
        </section>
      )}
    </>
  );
};

