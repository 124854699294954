
import './App.css';
import SerivesPage from './pages/ServiesPage/SerivesPage';
import Nav from './components/Nav/Nav';
import Footer from './components/Footer/Footer';
import { Route, Routes  } from 'react-router-dom';
import Seatreservation from './pages/SeatReservation/Seatreservation';
import PassngerData from './pages/passngerData/PassngerData';
import AirPlanData from './pages/AirPLanData/AirPlanData';
import OverviewCheckout from './pages/OverviewCheckout/OverviewCheckout';
import OverviewReseat from './pages/OverviewReseat/OverviewReseat';
import MealsOnBoard from './pages/MealsOnBoard/MealsOnBoard';
import AdditionalBaggage from './pages/AdditionalBaggage/AdditionalBaggage';
import Pets from './pages/pets/Pets';
import SpecialEquipment from './pages/SpecialEquipment/SpecialEquipment';
import AirportLounge from './pages/AirportLounge/AirportLounge';
import UnaccompaniedMinor from './pages/UnaccompaniedMinor/UnaccompaniedMinor';
import PriorityCheckIn from './pages/PriorityCheckIn/PriorityCheckIn';
import PriorityBoarding from './pages/PriorityBoarding/PriorityBoarding';
import PassngerData2 from './pages/passngerData2/PassngerData2';
import SignIn from './pages/SignIn/SignIn';
import NotFound from './pages/notFound/NotFound';
import Return from './pages/return/Return';
import ServiceDetails from './pages/ServiceDetails/ServiceDetails';
import { Cart } from './Cart/Cart';
import { Terms } from './pages/terms/terms';
import AboutUs from './pages/AboutUs/AboutUs';
import Privacy from './pages/Privacy/privacy';
import Warp from './components/common/Warp';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import PaymentStatus from './pages/PaymentStatus';


function App() {
  return ( 
    <Warp> 
      
      
     <Routes> 
     
      {/* <Route path='/' element={<SerivesPage/> }/> */}
      <Route path='/:CategorieId/Seatreservation' element={<Seatreservation/>}/> 
      <Route path='PassngerData' element={<PassngerData/>}/>  
      <Route path='AirPlanData' element={<AirPlanData/>}/> 
      <Route path='OverviewCheckout' element={<OverviewCheckout/>} /> 
      <Route path='OverviewReseat' element={<OverviewReseat/>}/> 
      <Route path='/:CategorieId/MealsOnBoard' element={<MealsOnBoard/>}/> 
      <Route path="categoryServices/:CategorieId" element={<AdditionalBaggage/>}/> 
      <Route path='Pets' element={<Pets/>}/> 
      <Route path='SpecialEquipment' element={<SpecialEquipment/>}/> 
      <Route path='AirportLounge' element={<AirportLounge/>}/> 
      <Route path='UnaccompaniedMinor' element={<UnaccompaniedMinor/>}/> 
      <Route path='PriorityCheckIn' element={<PriorityCheckIn/>}/> 
      <Route path='PriorityBoarding' element={<PriorityBoarding/>} /> 
      <Route path='PassngerData2' element={<PassngerData2/>}/> 
      <Route path='SignIn' element={<SignIn/>}/> 
      <Route path='Return' element={<Return/>}/> 
      <Route path='terms' element={<Terms/>}/>
      <Route path='about' element={<AboutUs/>}/>
      <Route path='privacy' element={<Privacy/>} />
                <Route path="/services/:serviceId" element={<ServiceDetails/>} />
                <Route path="/" element={<SerivesPage/>} />
                <Route path="/payment-status" element={<PaymentStatus/>} />
           <Route path='Cart' element={<Cart/>}></Route>
      <Route path='/*'  element={<NotFound/>}/>
      <Route path='/404'  element={<NotFound/>}/>
    
     </Routes> 
  
    
     </Warp>
  );
}

export default App;
