// languageSlice.js
import { createSlice } from '@reduxjs/toolkit';
import translationEN from './../local/en.json'
import translationDE from './../local/de.json'

let initialState={ 
    lang:"en",
    translations:translationEN,
    languageLoading: false,
   }

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
 
    changeLanguage: (state, action) => {
      state.lang = action.payload;
      if(action.payload==='en'){
        state.translations=translationEN
      }else{
        state.translations=translationDE
      }
      window.localStorage.setItem("lang",action.payload);
    }
  },
});

export const {
changeLanguage,

} = languageSlice.actions;



export default languageSlice.reducer;
