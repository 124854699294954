import React, { useEffect, useState } from 'react'
import Loader from '../components/common/Loader';
import FetchData from '../components/Api/FetchData';
import { Link, useLocation } from 'react-router-dom';

const PaymentStatus = () => {
    const [query,setQuery]=useState();
    const [load,setLoad]=useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

     useEffect(()=>{
      const pathAfter = location.search.split('?');
      const keyValuePairs = pathAfter[1].split('&');
      console.log('keyValuePairs',keyValuePairs)
      const result = {};
      keyValuePairs.forEach(keyValue => {
        const [key, value] = keyValue.split('=');
        result[key] = value;
      });
      console.log('result',result)
      setQuery(result)
      console.log('queryParams',result)
     },[])
     if(load){
        return <Loader/>
     }
  return (
    <section className="py-5 ">
    <div className="container">
      <div className="row my-5 border-1  justify-between items-center" >
        {query?.status==='success'?(
<div className="col-lg-12" >
<div className=" text-center justify-content-center "  >
<i className="fa-solid fa-circle-check" style={{color:'#133686',fontSize:'90px',marginBottom:'30px'}}></i>

 <div className="no-page__title text-30" >
   {/* 40<span className="text-blue-1">4</span> */}
  Payment Successful!
 </div>
 {/* <p>{query?.message}</p> */}
 <div className="d-flex justify-content-center gap-3 mt-40 md:mt-20">
   <Link
     to='/'
     className="home-payment-btn "
   >
     Go back to home
   </Link>
   <Link
     to='/OverviewReseat'
     className="OverviewReseat-payment-btn"
   >
     Go to Invoice
   </Link>
 </div>
</div>

</div>
        ):(
          <div className="col-lg-12">
          <div className="no-page  text-center justify-content-center " >
          <i className="fa-solid fa-circle-xmark" style={{color:'#900000',fontSize:'90px',marginBottom:'30px'}}></i>
            <div className="no-page__title text-30 text-danger" >
              {/* 40<span className="text-blue-1">4</span> */}
             Payment failed!
            </div>
             <p>{query?.message}</p>
            <div className="d-flex justify-content-center gap-1 mt-40 md:mt-20">
              <Link
                to='/'
                className="button -md -dark-1 bg-blue-1 text-white"
              >
                Go back to home
              </Link>
          

            </div>
          </div>
          
        </div>
        )}
        
   
      </div>
    </div>
  </section>
  )
}

export default PaymentStatus