import React from 'react' 
import "./OverviewReseat.css"
import Reseat from '../../components/Reseat/Reseat' ; 
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const OverviewReseat = () => {
  const {translations}=useSelector((state)=>state.languageReducer)
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='Cover'>  
   
    <div className='container'>    
    
    <h1 className='pt-5 text-white fw-bold'>{translations.FLY}</h1> 
    <p style={{color:'#D6D9E4'}}>{translations.DISCOVER}</p>
   
    
         
    <div className="  " >
     <Reseat/>
   </div>
    </div> 

  
    </div>
  )
}

export default OverviewReseat