
import axios from "axios";
import GetBaseUrl from "./GetBaseUrl";

const PostData =  async (Url,postData,token,locale=null) => {

  const base = GetBaseUrl();
 
       try{
        const response = await axios.post(base + Url,JSON.stringify(postData),{
          headers: {
            Authorization:`Bearer ${token}`,
            "local":locale,
            'Content-Type': 'application/json', 
            // Adjust this based on your API requirements
          },
        });
        return response;
       }catch(error){
        return error.response;
       }
               
    
};

export default PostData;
