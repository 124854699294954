import React, { useEffect, useState } from 'react';
import FlightInfo from '../FlightInfo/FlightInfo';
import PaymentForm from '../PaymentForm/PaymentForm';
import { format } from "date-fns";
import Total from '../common/Total';
import PostData from '../Api/PostData';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = () => {
  const {translations}=useSelector((state)=>state.languageReducer)
  const navigate = useNavigate();
  const [flight,setFlight]=useState({});
  const [user,setUser]=useState({});
  const [seats,setSeats]=useState([]);
  const [seatsPost,setSeatsPost]=useState([]);
  const [totalPriceSeats,setTotalPriceSeats]=useState("");
  const [serveces,setServeces]=useState([]);
  const [servecesPost,setServecesPost]=useState([]);
  const [error,setError]=useState("");
  const [load,setLoad]=useState(false);
  const total=Total();
  useEffect(()=>{
    let flight=localStorage.getItem('flight');
      if(flight){
        flight=JSON.parse(flight);
        setFlight(flight)
        console.log('flight',flight)
      }
      let user=localStorage.getItem('user');
      if(user){
        user=JSON.parse(user);
        setUser(user)
      }
      let seats=localStorage.getItem('seats');
      if(seats){
        seats=JSON.parse(seats);
        let total=0;
        const arr1=[];
        seats.map((seat)=>{
          total+=parseFloat(seat.seat.price);
          arr1.push(seat.seat.id)
        })
        setSeatsPost(arr1);
        setTotalPriceSeats(total);
        setSeats(seats)
      }
      let serveces=localStorage.getItem('serveces');
      if(serveces){
        serveces=JSON.parse(serveces);
        const arr2=[];
        serveces.map((ser)=>{
          arr2.push({id:ser.id,quantity:ser.count})
        })
        setServecesPost(arr2);
        setServeces(serveces)
      }
  },[])
  const token = Cookies.get('myCookie');

  const ReservationHandle=()=>{
    setLoad(true);
    PostData('book/reservation',{
      flight_id:flight.id,
      services:servecesPost,
      seats:seatsPost,
      name:user.first+" "+user.last,
      email:user.email,
      phone:user.mobile,
      },token).then((data)=>{
      if(data.status===200){

        localStorage.setItem('serveces',"");
        localStorage.setItem('seats',"");
        localStorage.setItem('reservation',JSON.stringify(data.data.data));
 
        window.location.href = data.data.url
      }else{
        setError('please try again later')
        setLoad(false);
      }
     
    }).catch((error)=>{
      setError(error)
      setLoad(false);
    })
  }
  return (
    <div className='d-flex justify-content-center'>
      <div className=" mb-4  text-center d-flex flex-column flex-lg-row align-items-center justify-content-center checkout-form" style={{ width: "1000px",borderRadius:'30px', backgroundColor: "white", padding: "20px" }}>
        <div className=" w-100">
          <h2 className='fw-bold'>{translations.Overview}</h2>
          <p style={{ fontSize: "14px", color: "#4A5970" }}>{translations.overview_lists}</p>
          <div className="col-12 col-lg-6 order-lg-2 mb-4 w-100">
            <FlightInfo
              from={flight.dep_port}
              to={flight.arr_port}
              date={flight.departure_date&&format(new Date(flight.departure_date), "dd,MMM yyyy")}
              passengers={seats.length}
              flightNumber={flight.flight_no}
            />

       
              {/* List items */}
              <ul className="list-group mb-3">  
      {seats?.map((seat,idx)=>(
         <li className="d-flex justify-content-between lh-condensed" key={idx}> 
        
         <div className='d-flex gap-2'>
          <p className="my-1 text-start" style={{color:"#FACE3E"}}> seat {idx+1} : </p>
          <p  className="my-1 fw-bold" style={{color:"#273771"}}>{seat.seat.row}{seat.seat.column}</p>
         </div>
         <span className="text-muted my-1"><span className='me-1' style={{color:"#FDECB6"}}>{translations.EGP}</span> {seat.seat.price}</span>
       </li>
      ))} 
              <li className="d-flex justify-content-between lh-condensed">
              <div>
            <p  className="my-1 para">{translations.Seat_reservation}</p>
           
          </div>
          <span className=" my-1 para">{translations.EGP} {totalPriceSeats}</span>
        </li>
        {serveces?.length>0&&(
        <h4 className='text-start fw-bold mt-3' style={{color:"#273771"}}>
       Services
      </h4>
      )}
        {serveces.map((servece,id)=>(
            <li key={id} className="my-1 d-flex justify-content-between lh-condensed">
            <div className='d-flex'>
              <p className="my-1 text-start" style={{maxWidth:'250px'}}>{servece.title}</p>
            </div>
            <div className='d-flex gap-4'>
              <p className="my-1 ">{servece.price} x  {servece.count}</p>
              <p className="text-muted my-1 " >{translations.EGP} {servece.price*servece.count}</p>
            </div>
            </li> 
        ))}
           
        <hr/>
        <li className=" para d-flex justify-content-between">
          <span >{translations.Total}</span>
          <div className='fw-bold'>{translations.EGP} {total}</div>
        </li>
      
        <li className=" mt-5  d-flex justify-content-center"> 
        {load?(
           <button onClick={ReservationHandle} className='Login-button d-flex justify-content-center align-items-center gap-2' style={{width:'200px',borderRadius:'10px'}}>
           <div class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
           <div>{translations.loading}...</div>
           </button>
        ):(
          <button onClick={ReservationHandle} className='Login-button' style={{width:'200px',borderRadius:'10px'}}>{translations.Complete}</button>
        )}
        </li>
      </ul>
      <p>{error}</p>
         </div>
        </div>
        
{/* <div className='d-flex justify-content-center'>
<PaymentForm/>
</div> */}
  


      </div>
    </div>
  );
};

export default CheckoutForm;
