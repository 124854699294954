import { format } from 'date-fns';
import React, { useEffect, useState } from 'react'
import FetchData from '../Api/FetchData';
import { useNavigate } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function FlightModal({ModalOpen,HandleClose,link='null',time=null,description}) {
    const {translations,lang}=useSelector((state)=>state.languageReducer)
    const navigate = useNavigate();
    const [error,setError] = useState('');
    const [load,setLoad] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [form,setForm]=useState({
        flight_no:"",
        departure_date:"",
        // arrival_date:""
      })
      useEffect(()=>{
        setError('')
      },[form])
      useEffect(()=>{
        setForm({...form,departure_date:format(startDate,"yyyy-MM-dd")})
      },[startDate])
      useEffect(()=>{
        setForm({...form,arrival_date:format(endDate,"yyyy-MM-dd")})
      },[endDate])
      const handelForm=(e)=>{
        setForm({...form,[e.target.name]:e.target.value})
      }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoad(true)
        FetchData(`/flights?flight_no=${form.flight_no}&departure_date=${form.departure_date}`).then((data)=>{
          console.log('data.status',data.status)
          if(data.status===200){
            
            localStorage.setItem('serveces','')
            localStorage.setItem('seats','')
            localStorage.setItem('flight',JSON.stringify(data.data))
            localStorage.setItem('description',JSON.stringify(description))
            let currentDate = new Date();
            currentDate.setHours(currentDate.getHours() + 1);
            localStorage.setItem('date',JSON.stringify(currentDate))
            if(link){
              if(time){
                let flight=data.data;
                let dateTimeStr = `${flight.departure_date}T${flight.std}`;
                let dateTime = new Date(dateTimeStr);
                dateTime.setHours(dateTime.getHours() - 6);
                if(new Date()>dateTime){
                  toast(translations.error_Serveses)
                  window.location.reload();
                }else{
                   navigate(link); 
                }   
              }else{
                navigate(link)
              }
              
            }else{
              window.location.reload();
            }
          }else{
            setError('No flight found for the specified details')
          }
          
        
        setLoad(false)
          
        }).catch((error)=>{
          setLoad(false)
        })
        //
      };
  return (
    <div className={`modal fade ${ModalOpen ? "show" : ""}`} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden={!ModalOpen} style={{ display: ModalOpen ? "block" : "none" }}>
         
    <div className="background">
  
      <div className="" style={{width:'700px'}}>
   
      <form className="flight-form  position-relative" onSubmit={handleSubmit}>
      <div className="close-modal" onClick={HandleClose}>
      <i class="fa-solid fa-circle-xmark"></i>
      </div>
<div className="row text-center">
  <h2>{translations.Match_Details}</h2>

</div>

<div className="d-flex flex-column   gap-5 mt-4">
  <div className="d-flex flex-column  gap-2 " >
  <label className="fw-bold">{translations.From}</label>
  <div className="date-icon  " >
   <ReactDatePicker
     selected={startDate}
     className="form-control ps-5"
     onChange={(date) => setStartDate(date)}
   />
        <i class="fa-solid fa-plane-up" aria-hidden="true"></i>
       </div>
  </div>

  {/* <div className="d-flex flex-column flex-sm-row gap-2 align-items-center">
  <label className="fw-bold">{translations.To}</label>
  <div className="date-icon col">
              <ReactDatePicker
              className="form-control ps-5 "
        selected={endDate}
        onChange={(date) => setEndDate(date)}
      />
        <i className="fa-solid fa-plane-arrival "></i>
          </div> 
  </div> */}

 
</div>  
<br/>
{/* <div className="row">  
<h4>Flight dates</h4>
<p className="para">Please enter the date on which you plan to travel for your flights.</p> 
</div>  */}


{/* <div className="row"> 
<input type="date" className="form-control" id="date" name="date"/>
</div>   */}



{/* <div className="d-flex my-2"> 
   <div style={{color:"gray" ,marginRight:"30px"}}> <input type="checkbox"/> Today</div> 
   <div style={{color:"gray" ,marginRight:"30px"}}> <input type="checkbox"/> Flight Day</div>
   <div style={{color:"gray" ,marginRight:"30px"}}> <input type="checkbox"/> Selection</div>
</div> */}

<br/>


{/* <p className="para">Please enter the number of your flight booking.</p>  */}

<div className="d-flex flex-column gap-2"> 
<h5 className='fw-bold'>{translations.Flight_number}</h5>
<h6 style={{color:'#646263'}}>{translations.fight_Please_enter}</h6>
<input
type="text" 
className="form-control col" 
id="date" 
name="flight_no" 
placeholder="NE: 1001"
value={form.flight_no}
required
onChange={(e)=>handelForm(e)}
/>

</div> 
<p className="text-danger mt-2">{error}</p>
<div className="d-flex justify-content-center mt-3">
 {load?(
 <button  className=" button-checkout d-flex justify-content-center align-items-center gap-2">
  <div class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
  <div>{translations.loading}...</div>
  </button>
 ):(
  <button type="submit" className=" Login-button" style={{width:'200px'}}>{translations.Choose}</button>
 )}
</div>
      </form>
      </div>
     
    </div>
  </div>
      
    
  )
}

export default FlightModal
