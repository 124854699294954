import React, { useEffect, useRef, useState } from 'react';
import SeatRow from '../SeatRow/SeatRow';
import './airplane.css'; // Make sure this CSS file contains updated styles
import Note from '../common/Note';

const AirplaneBody = ({flight,onSelectSeat,selectedSeat,labels }) => {
  //Define your seat labels here. Adjust them to match your airplane's configuration
  const [ModalOpen,setModalOpen]=useState('')
  const [seats,setSeats]=useState([]);
  const seatLabels = ['A', 'B', 'C', 'D', 'E', 'F'];
  const selectSeat=(seat)=>{
    if(seat.note){
      setModalOpen(seat.note)
    }
    onSelectSeat([...selectedSeat,{seat:seat,seatLabel:seat.column,rowNumber:seat.row}]);
  }
  const deleteSeat=(seatDeleted,rowNumber,seatNumber)=>{
    let seats= selectedSeat.filter(seat=>seat.seat.id!==seatDeleted.id)
    onSelectSeat(seats);
  }
  // useEffect(()=>{
  //   if(flight){
  //     let f=[];
  //     let WindowLength=0;
  //     let middleLength=0;
  //     let sealLength=0;
  //     let max=-1;
  //     if(flight.seatCategories?.Front?.Window){
  //       WindowLength=flight.seatCategories?.Front.Window.length
  //       if(WindowLength>max){
  //         max=WindowLength;
  //       }
  //     }
  //     if(flight.seatCategories?.Front?.Middle){
  //       middleLength=flight.seatCategories?.Front.Middle.length
  //       if(middleLength>max){
  //         max=middleLength;
  //       }
  //     }
  //     if(flight.seatCategories?.Front?.Aisle){
  //       sealLength=flight.seatCategories.Front.Aisle.length
  //       if(sealLength>max){
  //         max=sealLength;
  //       }
  //     }
     
    
  //     if(flight.seatCategories?.Front.Window){
  //       for(let i=0; i<max;i+=2){
  //         if(flight.seatCategories.Front.Window){
  //           f.push(flight.seatCategories.Front.Window[i])
  //         }else{
  //           e.push(undefined)
  //         }
  //         if(flight.seatCategories.Front.Middle){
  //           f.push(flight.seatCategories.Front.Middle[i])
  //         }else{
  //           e.push(undefined)
  //         }
  //         if(flight.seatCategories.Front.Aisle){
  //           f.push(flight.seatCategories.Front.Aisle[i])
  //         }else{
  //           e.push(undefined)
  //         }
          
          
          
  //         if(i+1<flight.seatCategories.Front.Window.length){
  //           if(flight.seatCategories.Front.Window){
  //             f.push(flight.seatCategories.Front.Window[i+1])
  //           }else{
  //             e.push(undefined)
  //           }
  //           if(flight.seatCategories.Front.Middle){
  //             f.push(flight.seatCategories.Front.Middle[i+1])
  //           }else{
  //             e.push(undefined)
  //           }
  //           if(flight.seatCategories.Front.Aisle){
  //             f.push(flight.seatCategories.Front.Aisle[i+1])
  //           }else{
  //             e.push(undefined)
  //           }
  //         }
  //       }
  //     }
     
  
  //     let m=[];
  //     WindowLength=0;
  //     middleLength=0;
  //     sealLength=0;
  //     max=0;
  //     if(flight.seatCategories?.Middle.Window){
  //       WindowLength=flight.seatCategories?.Middle.Window.length
  //       if(WindowLength>max){
  //         max=WindowLength;
  //       }
  //     }
  //     if(flight.seatCategories?.Middle.Middle){
  //       middleLength=flight.seatCategories?.Middle.Middle.length
  //       if(middleLength>max){
  //         max=middleLength;
  //       }
  //     }
  //     if(flight.seatCategories?.Middle.Aisle){
  //       sealLength=flight.seatCategories.Middle.Aisle.length
  //       if(sealLength>max){
  //         max=sealLength;
  //       }
  //     }
  //     if(max){
  //       for(let i=0; i<max;i+=2){
  //         if(flight.seatCategories.Middle.Window){
  //           m.push(flight.seatCategories.Middle.Window[i])
  //         }else{
  //           e.push(undefined)
  //         }
  //         if(flight.seatCategories.Middle.Middle){
  //           m.push(flight.seatCategories.Middle.Middle[i])
  //         }else{
  //           e.push(undefined)
  //         }
  //         if(flight.seatCategories.Middle.Aisle){
  //           m.push(flight.seatCategories.Middle.Aisle[i])
  //         }else{
  //           e.push(undefined)
  //         }
          
          
  //         if(i+1<max){
  //           if(flight.seatCategories.Middle.Window){
  //             m.push(flight.seatCategories.Middle.Window[i+1])
  //           }else{
  //             e.push(undefined)
  //           }
  //           if(flight.seatCategories.Middle.Middle){
  //             m.push(flight.seatCategories.Middle.Middle[i+1])
  //           }else{
  //             e.push(undefined)
  //           }
  //           if(flight.seatCategories.Middle.Aisle){
  //             m.push(flight.seatCategories.Middle.Aisle[i+1])
  //           }else{
  //             e.push(undefined)
  //           }
  //         }
  //       }
  //     }
     
    
  //     let e=[];
  //     WindowLength=0;
  //     middleLength=0;
  //     sealLength=0;
  //     max=0;
  //     if(flight.seatCategories?.Exit.Window){
  //       WindowLength=flight.seatCategories?.Exit.Window.length
  //       if(WindowLength>max){
  //         max=WindowLength;
  //       }
  //     }
  //     if(flight.seatCategories?.Exit.Middle){
  //       middleLength=flight.seatCategories?.Exit.Middle.length
  //       if(middleLength>max){
  //         max=middleLength;
  //       }
  //     }
  //     if(flight.seatCategories?.Exit.Aisle){
  //       sealLength=flight.seatCategories.Exit.Aisle.length
  //       if(sealLength>max){
  //         max=sealLength;
  //       }
  //     }
  //     if(max>0){
  //       for(let i=0; i<max;i+=2){
  //         if(flight.seatCategories?.Exit.Window){
  //           e.push(flight.seatCategories?.Exit.Window[i])
  //         }else{
  //           e.push(undefined)
  //         }
  //         if(flight.seatCategories?.Exit.Middle){
  //           e.push(flight.seatCategories?.Exit.Middle[i])
  //         }
  //         else{
  //           e.push(undefined)
  //         }
  //         if(flight.seatCategories?.Exit.Aisle){
  //           e.push(flight.seatCategories?.Exit.Aisle[i])
  //         }
  //         else{
  //           e.push(undefined)
  //         }
  //         if(i+1<max){
  //           if(flight.seatCategories?.Exit.Window){
  //             e.push(flight.seatCategories?.Exit.Aisle[i+1])
  //           }else{
  //             e.push(undefined)
  //           }
  //           if(flight.seatCategories?.Exit.Window){
  //             e.push(flight.seatCategories?.Exit.Middle[i+1])
  //           }else{
  //             e.push(undefined)
  //           }
  //           if(flight.seatCategories?.Exit.Window){
  //             e.push(flight.seatCategories?.Exit.Window[i+1])
  //           }else{
  //             e.push(undefined)
  //           }

          
          
         
  //         }
  //       }
  //     }
    
      
      
  //       const fr=splitTo2D(f, 6);
  //       setFront(fr);
  //       const mi=splitTo2D(m, 6);
  //       setMiddle(mi);
  //       const ex=splitTo2D(e, 6);
  //       setExit(ex);
  //       console.log('eeeeeeeeeeee',fr)
  //   }
  
  // },[flight])
  useEffect(()=>{
    console.log('labelssdasdasdsa',labels)
    console.log('flight.seats',flight.seats)
    if(flight.seats){
    setSeats(splitTo2D(flight.seats,6))
    }
  },[flight])
  function splitTo2D(array, rowLength) {
    // Calculate the number of rows needed
    const numRows = Math.ceil(array.length / rowLength);
    // Initialize the 2D array
    const result = [];
    // Fill the 2D array with elements from the 1D array
    for (let i = 0; i < numRows; i++) {
        result.push(array.slice(i * rowLength, (i + 1) * rowLength));
    }
    return result;
}

  return (
    <div className='mt-5'>
      <Note ModalOpen={ModalOpen} HandleClose={()=>setModalOpen('')}/>
      {/* Seat labels header */}
   
       <div className='d-flex justify-content-center' style={{width:'100%'}}>
       <div className="position-relative  Airplane-contain " >
        <div className='Airplane-image d-none d-md-flex'>
        <img src="/assets/airPlan33.png" alt="Airplane Image" className="airplane-image" />
        </div>
        <div className='seats '>
        {/* <div className='row my-3  ' style={{ marginLeft:'-4px' }}>
        <div className='col-3 '></div>
        {seatLabels.map((label, index) => (
           <>
          <div className={` ${index===2?'col-2':'col-1'}`}>
            {label}
          </div>          
        </>
        ))}
        
        </div> */}
        {/* <div className='d-flex flex-column gap-5  '>
     
       
         <div>
         {labels['A']?.map((rows, index) => (
        <div className='row'>
           <div className='col-3 mb-2'>{index+1}</div>
          {rows.map((row,id)=>(
          
            <div className={`${id===2?'col-2':'col-1'}`} >
            <div className={`seat`}>
              {row&&selectedSeat.filter((select)=>select.seat.id===row.id).length>0?(
              <img src="/assets/green2.png" onClick={()=>deleteSeat(row,index+1,id)}  style={{ width: "20px" }} />
              ):!row||row?.status===0?(
                <img style={{width:"20px" ,backgroundColor:"gray" }} src="/assets/gray2.png"/> 
              ):(
                <div onClick={()=>selectSeat(row,index+1,id)}>
                <img style={{width:"20px" ,backgroundColor:"blue"}} src="/assets/blue2.png"/> 
              </div>
              )}
           </div>
            </div>

          
          ))}
          </div>
        ))}
        </div>
      
        </div> */}
        <div className='d-flex my-4  gap-1' style={{ marginLeft:'8px' }}>
       
        <div className='d-flex gap-2 justify-content-center'>
      
          <div className='d-flex flex-column gap-2  me-5' style={{marginTop:'55px'}}>
          {labels['A'].map((_i,index)=>(
           <div>{index+1}</div>
        ))}

          </div>
         
          {Object.keys(labels).map((label,index)=>(
             <div className='d-flex flex-column gap-2'>
                 <div className='text-center mb-3' style={{width:"20px"}}>
           {label}
         </div>  
                {labels[label].map((seat,id)=>(
                 <div className={`seat`}>
                 {selectedSeat.filter((select)=>select.seat.id===seat.id).length>0?(
                 <img src="/assets/green2.png" onClick={()=>deleteSeat(seat,index+1,id)}  style={{ width: "20px" }} />
                 
                ):seat?.status===1&&seat?.note?(
                  <img style={{width:"20px" ,backgroundColor:"gray" }} src="/assets/parbel12.png" onClick={()=>selectSeat(seat)}/> 
                 ):!seat||seat?.status===0?(
                  seat?.note?(
                    <img style={{width:"20px",height:'22px' ,backgroundColor:"#d900c3" }} src="/assets/zz.png"/> 
                  ):(
                    <img style={{width:"20px" ,backgroundColor:"gray" }} src="/assets/gray2.png"/> 
                  )
                 ):(
                   <div onClick={()=>selectSeat(seat)}>
                   <img style={{width:"20px" ,backgroundColor:"blue"}} src="/assets/blue2.png"/> 
                 </div>
                 )}
              </div> 
                ))}
             </div>      
        ))}

        </div>
        </div>
        </div>
      </div>
      </div>  
      
    </div>
  );
};

export default AirplaneBody;






