import React from 'react';
import "./PassngerData.css"
import FormPassengerinfo from '../../components/form/FormPassengerinfo'; 
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const PassngerData = () => { 
  const {translations}=useSelector((state)=>state.languageReducer)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className=''>  
    <div>
    <div className='Cover'>    
    
     <div className='container pt-5'>
     <h1 className='text-white fw-bold '>{translations.FLY}</h1> 
    <p className='text-white ms-2'>{translations.DISCOVER}</p>
   
    
   
    <div className="d-flex justify-content-center align-items-start" >
   <FormPassengerinfo/> 
   </div>
     </div>
    </div> 
   
    </div>
   
    </div>
  )
}

export default PassngerData;