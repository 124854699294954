import React, { useEffect, useState } from "react";
import "./ServiesPage.css";
import Cards from "../../components/Cards/Cards";
import FetchData from "../../components/Api/FetchData"; // Ensure this is the correct path
import { ToastContainer, toast } from 'react-toastify';

import Cookies from 'js-cookie';
import { getDomainName } from "../../components/Api/GetBaseUrl";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Total from "../../components/common/Total";
import FlightInfo from "../../components/FlightInfo/FlightInfo";
import { format } from "date-fns";
import Loader from "../../components/common/Loader";
import { useSelector } from "react-redux";
import FlightModal from "../../components/common/FlightModal";


const ServicesPage = () => { 
  const {translations,lang}=useSelector((state)=>state.languageReducer)
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token = Cookies.get('myCookie'); // Assuming token is stored in cookies
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [link,setLink]=useState('')
  const [time,setTime]=useState('')
  const [description,setDescription]=useState('')
  const navigate = useNavigate();
  const HandleReserve=(value,time,descriptionAll)=>{
    
    let flight=localStorage.getItem('flight');
    if(flight){
      flight=JSON.parse(flight);
      let dateTimeStr = `${flight.departure_date}T${flight.std}`;

      let dateTime = new Date(dateTimeStr);
      dateTime.setHours(dateTime.getHours() - 6);
      if(new Date()>dateTime){
        console.log("time",dateTime)
        toast(translations.error_Serveses)
      }else{
         localStorage.setItem('description',JSON.stringify(descriptionAll))
         navigate(value); 
      }

    }else{
      setIsModalOpen(true)
      setLink(value);
      setDescription(descriptionAll)
      setTime(time);
    }
  }
  const HandleClose=()=>{
    setIsModalOpen(false)
  }
  const handleCheckout = () => {
    // if(token){
    //   navigate('OverviewCheckout');
    // }else{
    //   localStorage.setItem('location',JSON.stringify('/OverviewCheckout'))
    //   navigate('SignIn');
    // }
    // let seats=localStorage.getItem('seats');
    // if(seats){
      navigate('PassngerData');
    // }else{
    //   toast(translations.please_select_seats)
    //   navigate('10/Seatreservation');
    // }
    
      // Change '/checkout' to your desired path
  };
 const [flight,setFlight] =useState(null);
  useEffect(() => { 
    window.scrollTo(0, 0);
    setIsLoading(true);
    const fetchServices = async () => {
      const data = await FetchData('/service-categories', token, lang);
      console.log('Fetched data:', data); // Debug to see what data looks like
      if (data.status===200) {
        setServices(data.data); // Set the data directly as there is no 'items' array
        setIsLoading(false);
        console.log('asdasdasdas',data.data)
      } else {
        console.error('Failed to fetch services:', data.error || "No data returned");
        setIsLoading(false);
      }

    }; 
    
      let currentDate = new Date();
      const flight=localStorage.getItem('flight');
      if(flight){
        let specificDate=localStorage.getItem('date');
        if(specificDate){
          specificDate=JSON.parse(specificDate)
          specificDate = new Date(specificDate);
        if (currentDate < specificDate) {
          setFlight(JSON.parse(flight))
        } else {
          localStorage.setItem('serveces','')
          localStorage.setItem('seats','')
          localStorage.setItem('flight','') 
        }
      }else{
        localStorage.setItem('flight','')  
      }
      }  
    
  
    fetchServices();
   

// Compare the dates

    
   

  }, [token,lang]);
 const total=Total();
  const domainName=getDomainName();
  return (
    <> 
    <ToastContainer />
   <FlightModal ModalOpen={isModalOpen} description={description} link={link} time={time} HandleClose={HandleClose}/>
    {flight&&(
      <div style={{backgroundImage:"url('/assets/Header 2.jpg')",backgroundRepeat:'no-repeat',backgroundSize:'cover',width:'100%'}}>
      <div style={{backgroundColor:'rgb(15 21 43 / 25%)',width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
      <div className="rounded-2 my-4 p-3 mx-2" style={{backgroundColor:'white',width:'600px'}}>
        <FlightInfo
              from={flight.dep_port}
              to={flight.arr_port}
              date={flight.departure_date&&format(new Date(flight.departure_date), "dd,MMM yyyy")}
              flightNumber={flight.flight_no}
          />
       {total>0?(
         <div className="d-flex flex-column-reverse gap-3 flex-sm-row justify-content-between align-items-center">
         <Link to="/Cart" className="payment-sammary m-0" >
         {translations.Payment_summary}
         </Link>
         <div  className="payment-sammary m-0" onClick={()=>setIsModalOpen(true)}>
         {translations.change_flight}
         </div>
          <div className="d-flex align-items-center  justify-content-between gap-4 justify-content-md-center" >
              <p className="m-0 p-0 fw-bold text-18" style={{fontSize:'20px'}}>
                {translations.EGP} {total}
              </p>
              <button className="button-checkout " onClick={handleCheckout} style={{}}>{translations.checkout}</button>
          </div>
      </div>
       ):(
        <div className="d-flex justify-content-between">
          <p className="text-danger">{translations.Please_select}</p>
          {/* <p className="change-flight" >Change Flight</p> */}
          <div  className="payment-sammary m-0" onClick={()=>setIsModalOpen(true)}>
          {translations.change_flight}
         </div>
     </div>
       )}
      </div>
      </div>
  </div>
    )}
      
      <div className="text-center mt-4 container" style={{width:"70%"}}>  
        <h3 className="Services fw-bold">{translations.Services}</h3>
        <p className="ServicesPara">
         
          {translations.flexible_booking}
        </p>
      </div>
      <div className="container my-4" >
 
        {isLoading ? (
          <div className="d-flex justify-content-center" style={{height:'80vh'}}> 
                   <img src="/assets/Loading.svg" className='main-loader' style={{width:'100px'}}/> 
          </div>
        ) : services.length > 0 ? (
          <div className="row ">
            {services.map((service, index) => {
              const imagePath = `${domainName}/${service.image}`;
              return (
                <div className="col-12 col-md-6 col-lg-4 "  key={index}>
                  <Cards
                    id={service.id}
                    imageSrc={imagePath}
                    title={service.title}
                    descriptionAll={service.description || "No description available."}
                    description={service.short_description || "No description available."}
                    link={service.title==="SEAT RESERVATION"||service.id===10?`/${service.id}/Seatreservation`:`/categoryServices/${service.id}`}
                    linkText={translations.RESERVE}
                    min_hours={service.min_hours}
                    HandleReserve={HandleReserve}
                  />
                </div>
                // service.title==='Meals on board'?`/${service.id}/MealsOnBoard`:
              );
            })}
          </div>
        ) : (
          <p>{translations.No_services}</p>  // Display this only if not loading and no services found
        )}
      </div> 
    </>
  );
};

export default ServicesPage;




