import React from 'react'

const Loader = () => {
  return (
    <div  className='d-flex  justify-content-center align-items-center' style={{height:'100vh'}}>
        <img src="/assets/Loading.svg" className='main-loader' style={{width:'100px'}}/> 
    </div>
  )
}

export default Loader