import React, { useState } from 'react'

const Counter = ({ name, defaultValue, onCounterChange }) => {
    const [count, setCount] = useState(defaultValue);
    
    const incrementCount = () => {
        setCount(count + 1);
        onCounterChange(name, count + 1);
    };
    const decrementCount = () => {
      if (count > 0) {
        setCount(count - 1);
        onCounterChange(name, count - 1);
      }
    };
  
    return (
      <>
      
            <div className="d-flex items-center js-counter">
              <button
             
                className=" counter-pointer rounded-2  p-0"
                style={{fontSize:'50px'}}
                onClick={decrementCount}
              >
                <p className=" mb-2 p-0" >-</p>
              </button>
              {/* decrement button */}
              <div className="flex-center d-flex align-items-center text-20 mx-3 ">
                <div className="text-15 js-count">{count}</div>
              </div>
              {/* counter text  */}
              <button
                className="counter-pointer rounded-2  p-0"
                style={{fontSize:'40px'}}
                onClick={incrementCount}
              >
                 <p className="text-16 mb-2 p-0" >+</p>
              </button>
              {/* increment button */}
            </div>
       
      </>
    );
  };

export default Counter