import React, { useEffect, useState } from 'react'


function Note({ModalOpen,HandleClose,link='null'}) {

  return (
    <div className={`modal fade ${ModalOpen ? "show" : ""}`} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden={!ModalOpen} style={{ display: ModalOpen ? "block" : "none" }}>
         
    <div className="background">
  
      <div className="modal-body">
   
      <div className="flight-form position-relative">
      <div className="close-modal" onClick={HandleClose}>
      <i class="fa-solid fa-circle-xmark"></i>
      </div>

 
      <div className='mb-3 fw-bold '>
        {ModalOpen}
      </div>



      <div className='d-flex justify-content-end'>
      <button className='btn-ok' onClick={HandleClose}>Ok</button>
      </div>

      </div>
      </div>
     
    </div>
  </div>
      
    
  )
}

export default Note
