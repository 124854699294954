import React, { useEffect, useState } from 'react'
import { getDomainName } from '../../components/Api/GetBaseUrl';
import FetchData from '../../components/Api/FetchData';
import { useSelector } from 'react-redux';
import Loader from '../../components/common/Loader';
import { PageContent } from '../../components/common/PageContent';

function AboutUs() {
  const domainName=getDomainName();
  const [data,setData]=useState({});
  const [load,setLoad]=useState(false);
  const {translations,lang}=useSelector((state)=>state.languageReducer)

  useEffect(()=>{
    setLoad(true)
     FetchData('/aboutus','',lang).then((data)=>{
      console.log('resddasds',data)
      if(data.status){
        setData(data.data);
        setLoad(false)
      }
     }).catch((error)=>{
      setLoad(false)
     })
  },[])

  // Only try to create imagePath if data is not null

if(load){return(
  <Loader/>  // Display loader while fetching data
  )}
  return (
  
<section>
<PageContent title={data.title} page={data.page} description={data.description} image={data.image}/>
</section>

  )
}

export default AboutUs