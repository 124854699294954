import React, { useEffect, useState } from 'react';
import "./Cards.css";
import { Link, useNavigate } from 'react-router-dom';
import FetchData from "../../components/Api/FetchData";
import ReactDatePicker from "react-datepicker";
import { format } from 'date-fns';
import FlightModal from '../common/FlightModal';

const Cards = ({ imageSrc, title, description,descriptionAll, link, linkText,id ,HandleReserve,min_hours}) => {
  const navigate = useNavigate();
  const [services,setServices]=useState([]);
  const [seats,setSeats]=useState([]);
  const [totalSeats,setTotalSeats]=useState("");
  const[total,setTotal]=useState("");
  useEffect(()=>{
    let serveces=localStorage.getItem('serveces');
    if(serveces){
      serveces=JSON.parse(serveces);
      const s=serveces.filter((servece)=>servece.category_id===id)
      setServices(s);
      let t=0;
        s.map((servece)=>{
         t+=servece.price*servece.count
        })
        setTotal(t)
    }
    let seat=localStorage.getItem('seats');
    if(seat){
      seat=JSON.parse(seat);
      setSeats(seat);
      let to=0;
       seat.map((s)=>{
        
         to+=parseInt(s.seat.price)
        })
        setTotalSeats(to)
    }
   
  },[])
  
 


 

  return ( 

    // <Link to={link} style={{width:"100%",textDecoration:"none"}}  >
    // <div className="card  my-3">

    //   <img className="card-img-top"  src={imageSrc} alt={title} />

    <div className="card" style={{padding: "0px", marginBottom: "20px",width:'100%'}}>
     
      <img className="" src={imageSrc} alt={title} style={{height:'250px',width:'100%'}}/>
      <div className=' d-flex justify-content-end'>
         <div className='position-relative  message-container'>
         <i className="fa-regular fa-circle-question  message-container" style={{ margin: "5px"}}>
        </i> 
      <div className='message-show-details'>
         {description}
        </div>
         </div>
      </div>
      
     
      <div className="card-body d-flex flex-column justify-content-between container">
        <h5 className="card-title text-center fw-bold  " style={{color:'#292929'}} >
          {title}
        </h5>
        {/* <p className="card-text " >{description}</p>  */}
         {total>0?(
           <div className='d-flex justify-content-between mb-2 pb-2 ' style={{borderBottom:'1px solid black'}}>
           <p className='m-0 fw-bold' style={{fontSize:'18px'}}>EGP {total}</p>
           <i style={{color:'#027116',fontSize:'30px'}} class="fa-solid fa-circle-check"></i>
        </div>
         ):totalSeats>0&&id===10&&(
          <div className='d-flex justify-content-between mb-2 pb-2 ' style={{borderBottom:'1px solid black'}}>
           <p className='m-0 fw-bold' style={{fontSize:'18px'}}>EGP {totalSeats}</p>
           <i style={{color:'#027116',fontSize:'30px'}} class="fa-solid fa-circle-check"></i>
        </div>
         )}
        <div className='text-center d-flex justify-content-center mb-2 '>
          {/* <img src='assets/plus.png' style={{width: "15px"}} alt="Plus Icon"/> */}
          <div className="getActionCard" onClick={()=>HandleReserve(link,min_hours,descriptionAll)}>
          <Link  className='hover-text-link' >{linkText}</Link>
          <Link  className='hover-getActionCard'></Link>
          </div>

        </div>
      </div>
    </div> 
  ); 
}

export default Cards;
