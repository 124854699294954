import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FetchData from '../../components/Api/FetchData';
import Cookies from 'js-cookie';
import SpecialEquipmentCard from '../../components/SpecialEquipmentCard/SpecialEquipmentCard';

const ServiceDetails = () => {
    const { serviceId } = useParams();
    const [serviceDetails, setServiceDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const token = Cookies.get('token');
    const locale = 'en';

    useEffect(() => {
        const fetchServiceDetails = async () => {
            const data = await FetchData(`/services/${serviceId}`, token, locale);
            if (!data.error) {
                setServiceDetails(data);
            } else {
                console.error('Failed to fetch service details:', data.error);
            }
            setIsLoading(false);
        };

        fetchServiceDetails();
    }, [serviceId, token, locale]);

    return (
        <div className="container-fluid">
            {isLoading ? (
                <div>Loading...</div>
            ) : serviceDetails ? (
                <div>
                    <div className="row" style={{ padding: "20px", backgroundColor: "#D8DCE3" }}>
                        <h3 className="titleCard" style={{ marginLeft: "70px" }}>
                            {serviceDetails.title}
                        </h3>
                    </div>
                    <div style={{ width: "70%", marginTop: "50px" }} className="container text-start layout-wrapper">
                        <p className="text" style={{ width: "80%" }}>
                            {serviceDetails.description}
                        </p>
                        <SpecialEquipmentCard service={serviceDetails} />
                    </div>
                </div>
            ) : (
                <p>No service details available.</p>
            )}
        </div>
    );
};

export default ServiceDetails;
