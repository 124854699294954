
import React from 'react'
import { Link, json, useLocation, useNavigate } from 'react-router-dom' ; 
import "./Nav.css"
import Cookies from 'js-cookie';
import FetchData from '../Api/FetchData';
import { getDomainName } from '../Api/GetBaseUrl';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from '../../redux/languageSlice';

const Nav = ({logo}) => {
  const {translations,lang}=useSelector((state)=>state.languageReducer)
  const navigate = useNavigate();
  const domainName=getDomainName();
  const location = useLocation();
  const SignInHandel=()=>{
    localStorage.setItem('location',JSON.stringify(location.pathname))
  }
  const LogoutHandel=()=>{
    FetchData('/logout',Cookies.get("myCookie")).then((data)=>{
       console.log(data);
       if(data.status===200){
        Cookies.remove('myCookie');
        window.location.reload();
       }
    }).catch((error)=>{

    })
    
  }
  const dispatch=useDispatch()
  const change=(lang)=>{
    dispatch(changeLanguage(lang));
    // window.location.reload();
  }
  return (
  //   <nav className="navbar navbar-expand-lg navbar-light bg-transparnt" style={{padding:"0px"}}>
  //     <div className="container-fluid" >
  //       {/* Toggle button */}

  //       {/* Collapsible wrapper */}
  //       <div className="collapse navbar-collapse" id="navbarSupportedContent">
  //         {/* Navbar brand */}
  //         <Link className="navbar-brand mt-0 mt-lg-0" style={{padding:"0px" ,marginLeft:"200px"}} to="/">
  //           <img src="assets\Logo.png" height={50}  alt="Logo" loading="lazy" />
  //         </Link>

   
 
  //   <div className="d-flex align-items-center mx-5">
    
    
  //     {!Cookies.get("myCookie")&&(
  //    <Link to='SignIn' onClick={SignInHandel} className="text-reset me-1" style={{textDecoration:"none"}}> 
  //     <i className="fa-regular fa-user"> </i>  
  //    Sign in 
  //    </Link> 
  //     )}
      
      
    
    
  //   </div>
    
  // </div>
 


  // <div className="d-flex align-items-center mx-5">
  //      <Link to={"/terms"}> Terms&Condition</Link>
  // </div>

  //      <div className="d-flex align-items-center mx-5">
  //   <Link to={"/about"}></Link>
  //     </div>

      // <div className="d-flex align-items-center mx-5">

      //     <a className="text-reset me-1" href="#">
      //   <i className="fa-solid fa-earth-americas"></i>
      // </a>
      //  EN
      //   </div>

  //      <div className="d-flex align-items-center mx-5">

  //     <Link className="text-reset me-1 " to="SignIn">
  //      <i className="fa-regular fa-user"> </i>
  //        </Link>
  //     <Link to="SignIn" className="text-reset me-1" style={{textDecoration:"none"}}> Sign in </Link>

  //    </div>

  //    </div>

  //    </nav> 
  <nav class="navbar py-0 navbar-expand-lg position-relative navbar-light bg-white">
  <div class="container  ">
    <div>
    <Link class="navbar-brand" to="/">
      <img src={`${domainName}/${logo}`} height={60}  alt="Logo" loading="lazy" />
    </Link>
    </div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
     
      
    <div class="collapse navbar-collapse flex-grow-0  " id="navbarNavDropdown">
      <ul class="navbar-nav gap-1 align-items-center gap-lg-5">
        <div className='d-flex flex-column flex-lg-row gap-1 me-lg-5'>
        <li class="nav-item">
          <Link class={`nav-link ${location.pathname==='/'&&'active fw-bold'}`} aria-current="page" to="/">{translations.home}</Link>
        </li>
        <li class="nav-item">
          <Link class={`nav-link ${location.pathname==='/about'&&'active fw-bold'}`} to="/about">{translations.about}</Link>
        </li>
        <li class="nav-item">
          <Link class={`nav-link ${location.pathname==='/terms'&&'active fw-bold'}`} to="/terms">{translations.terms}</Link>
        </li>
        </div>
        <div className='d-flex flex-column flex-lg-row align-items-center gap-3'>
     
        <li class="nav-item dropdown me-3">
        
          <div className="d-flex align-items-center " id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fa-solid fa-earth-americas me-1"></i>
            {lang==='en'?'English':'German'}
          </div>
         
           <ul class="dropdown-menu" style={{ padding:'0px',width:'10px', }} aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item language-dropdown"  onClick={()=>change('en')}>English</a></li>
            <li><a class="dropdown-item language-dropdown" onClick={()=>change('de')}>German</a></li>
          </ul> 
        </li>
        {/* <li class="nav-item">
          
          {!Cookies.get("myCookie")?(
     <Link to='SignIn' onClick={SignInHandel} className="text-reset `" style={{textDecoration:"none"}}> 
      <i className="fa-regular fa-user me-1"> </i>  
     {translations.Sign_in}
     </Link> 
      ):(
        <button className="button-checkout"  onClick={LogoutHandel}> {translations.Logout}  </button>
      )}
       
        </li> */}
        </div>
       
      </ul>
    </div>
  </div>
</nav>
  );
};

export default Nav;
