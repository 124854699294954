import React, { useEffect, useState } from 'react'

const Total = () => {
    const [total, setTotal] = useState("");

    useEffect(() => { 
        let serveces=localStorage.getItem('serveces');
        let seats=localStorage.getItem('seats');
        let t=0;
         if(serveces){
           serveces=JSON.parse(serveces);
           
           serveces.map((servece)=>{
            t+=servece.price*servece.count
           })
           
         }
         if(seats){
           seats=JSON.parse(seats);
           
           seats.map((s)=>{
            t+=parseFloat(s.seat.price)
           })
          
         }
         setTotal(t)
     }, []);
     return total;
}

export default Total