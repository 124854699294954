import React from 'react';
import "./ChooseMeal.css"; 
import { Link } from 'react-router-dom';
import { getDomainName } from '../Api/GetBaseUrl';
import Counter from '../common/Counter';

const ChooseMeal = ({serviceDetails}) => {
  const domainName=getDomainName();
  const handleCounterChange = (name, value) => {
    
  }
  return (
    <div className='w-100 bg-white p-3 mt-4'>
      <div className=''>
       
       <div className='continar text-center' > 
       <div className='row'>
      
        {serviceDetails.map((meal,idx)=>(
            <div className='col-sm-12 col-md-12 col-lg-6  meal-option mb-5'>
            <img className='imgs' src={`${domainName}/${meal.image}`}/> 
            <div className='d-flex justify-content-end'>
            <i className="fa-regular fa-circle-question" style={{fontSize: "13px",marginRight:"10px" ,marginTop:"5px" ,color:"gray"}}></i> 

            </div> 
            <div className='d-flex mt-3 gap-5 justify-content-center align-items-center '>
            <div className='d-flex gap-1 align-items-center'><input type='radio' style={{width:'20px',height:'20px'}}/> 
            <p className='m-0'>{meal.title}</p></div>
            <div style={{color:"gray"}}>{meal.price} EGP </div>
            {/* <input style={{width:"40px"}} type='number'></input> */}
            <Counter
              name=""
              defaultValue={0}
              onCounterChange={handleCounterChange}
            />
            </div>
            <span > <span className='EGP'></span></span>
            </div>
        ))} 
       
       </div>  
       <br/>
     

       <hr/> 

       <div className='text-end py-2'>
        <span style={{fontSize:"20px"}} className='EGP'>EGP  199</span>
       </div> 
       <div className='text-end'>
        <span style={{fontSize:"15px" ,}} className='EGP'> .Total price for Meal</span>
       </div> 
       <div className='d-flex justify-content-end'>
       <Link to="/" className='btn'>Add the meal</Link>
       </div>
       </div>
      </div>
    </div>
  )
}

export default ChooseMeal
