import React from 'react' ;  
import "./ChooseSeatsConformationCard.css"
import { Link, useNavigate } from 'react-router-dom';

const ChooseSeatsConformationCard = ({flight,allSeatsSelected,onSelectSeat}) => {
  const navigate = useNavigate();
  const HandleChooseSeat=()=>{
    localStorage.setItem('flight',JSON.stringify(flight));
    localStorage.setItem('seats',JSON.stringify(allSeatsSelected))
    navigate('/')
  }
  const deleteSeat=(seatDeleted)=>{
    let seats= allSeatsSelected.filter(seat=>seat.seat.id!==seatDeleted.seat.id);
    console.log('allSeatsSelected',allSeatsSelected)
    console.log('seats',seats)
    console.log('seatDeleted',seatDeleted)
    onSelectSeat(seats);
  }
  return ( 
    <div className='position-relative d-flex justify-content-center'>
      {allSeatsSelected?.length>0&&(
   <div className="continar big9">  
   <div className="flight-route">
       <span className="city">{flight.dep_port}</span>
       <img src='/assets/arrow.png' className='arrow' style={{margin:"10px"}}/> 
       <span className="city">{flight.arr_port}</span>
     </div>
   
   
    <hr/>
   {allSeatsSelected?.map((seat,idx)=>(
     <div key={idx}>
        <div className='row'  >  

<div className="d-flex justify-content-between align-content-center">
       <div className="city">
       <div className='row d-flex align-content-center'> 

<span className="city"><img src='/assets/green2.png'  style={{width:"40px" ,height:"20px" }}/> {seat.rowNumber}{seat.seatLabel} </span>

</div>  
<div className='row'  > 
<ul className='city' style={{margin:"12px"}}>
   <li>
     {seat.seatLabel==="A"||seat.seatLabel==="B"||seat.seatLabel==="C"?"Left side seat ":"Right side seat "}  
   </li> 

     {seat.seatLabel==="A"||seat.seatLabel==="F"?<li>Window seat</li>:""}

</ul>

</div>  
       </div>
       <div className='d-flex flex-column justify-content-between align-items-end'>
       <div className="removeButton" onClick={()=>deleteSeat(seat)}>
      <i class="fa-solid fa-circle-xmark"></i>
      </div>
       <div className="d-flex align-items-start">
         <span
           style={{ fontSize: "25px", fontWeight: "bold" }}
           className="text-muted m-0"
         >
           EGP {seat.seat.price}
         </span>
         
       </div>
       </div>
     </div> 
     

</div>
<hr/> 
     </div>
   ))} 
   <div className='d-flex justify-content-center'> 
   <button onClick={HandleChooseSeat} className='Login-button text-center' >Choose this seat</button>
   
   </div>
     </div>
      )}
 
    </div>
  )
}

export default ChooseSeatsConformationCard ;
