import React from "react"; 
import "./Seatreservation.css" ; 
import AirplaneBody from "../../components/airplane/AirplaneBody";
import ChooseSeatsConformationCard from "../../components/ChooseSeatsConformationCard/ChooseSeatsConformationCard";
import { useEffect } from "react";
import FlightForm from "../../components/Form2/Formflightinfo"
import { useState } from "react";
import { json, Link, useNavigate, useParams } from "react-router-dom";
import FetchData from "../../components/Api/FetchData";
import ReactDatePicker from "react-datepicker";
import Note from "../../components/common/Note";
import Loader from "../../components/common/Loader";
const Seatreservation = () => {  
  const { CategorieId } = useParams();
  const [flight,setFlight]=useState({});
  const [labels,setLabels]=useState({
    "A":[],
    "B":[],
    "C":[],
    "D":[],
    "E":[],
    "F":[]
});
  const [load,setLoad] = useState(false);
  const [titleDescription,setTitleDescription] = useState('');
  
  const navigate = useNavigate();
  const [allSeatsSelected,setAllSeatsSelected]=useState([])

  function splitTo2D(array, rowLength) {
    // Calculate the number of rows needed
    const numRows = Math.ceil(array.length / rowLength);
    // Initialize the 2D array
    const result = [];
    // Fill the 2D array with elements from the 1D array
    for (let i = 0; i < numRows; i++) {
        result.push(array.slice(i * rowLength, (i + 1) * rowLength));
    }
    return result;
}
function compareByRow(a, b) {
  return a.row - b.row;
}
  useEffect(() => {
    window.scrollTo(0, 0);
    
    let description=localStorage.getItem('description');
    setTitleDescription(JSON.parse(description))
    let flight=localStorage.getItem('flight');
    flight=JSON.parse(flight)
    if(flight){
      setLoad(true)
      FetchData(`/flights?flight_no=${flight.flight_no}&departure_date=${flight.departure_date}`).then((data)=>{
        console.log('data.status',data.status)
        if(data.status===200){
          localStorage.setItem('flight',JSON.stringify(data.data))
          setFlight(data.data)
          let seats=data.data.seats;
          let object={}
          Object.keys(labels).map((label,idx)=>{
             let seatsFilter=seats.filter((seat)=>seat.column.toUpperCase()===label).sort(compareByRow)
             console.log(`seatsFilter ${label}`,seatsFilter)
             object={...object,[label]:seatsFilter}
          })
          setLabels(object)
          // seats.filter((seat)=>seat)
          console.log('sdasdsadasd',labels)
          setLoad(false)
        }
      }).catch((error)=>{
        setLoad(false)
      })
      
      
    }
  }, []);

  const handleSelectSeat = (seats) => {
    console.log('seatNumber',seats);
    setAllSeatsSelected(seats)
  };


  if(load){
    return(
      <Loader/>
    )  
  }

  return ( 
    <> 


    
    <div className="container">
      <div
        className="row "

      >
        <h3 className="mt-4 fw-bold" style={{color:'#273771'}}>
          Seat reservation
        </h3>
      </div>
      <div
      
        className="container text-start mt-4"
      >
           <div 
                      dangerouslySetInnerHTML={{
                        __html: titleDescription,
                      }}
                      style={{color:'#292929',fontWeight:'bold',fontSize:'18px'}}
                      className="text"
                    ></div>
        <div className="d-flex gap-2 flex-wrap">
        <div className="">
          <img style={{width:"15px" ,backgroundColor:"green"}} src="/assets/green2.png"/> 
          <span style={{fontSize:"12px", margin:"10px" }}>Selected</span>
        </div>  
        {/* <div className="col-6 col-sm-3">
          <img style={{width:"15px" ,backgroundColor:"purple"}} src="/assets/parbel12.png"/> 
          <span style={{fontSize:"12px", margin:"10px" }}>Suggestion</span>
        </div>  */}
        <div className="">
          <img style={{width:"15px" ,backgroundColor:"blue"}} src="/assets/blue2.png"/> 
          <span style={{fontSize:"12px", margin:"10px" }}>Available</span>
        </div>  
        <div className="">
          <img style={{width:"15px" ,backgroundColor:"gray" }} src="/assets/gray2.png"/> 
          <span style={{fontSize:"12px", margin:"10px" }}>Taken</span>
        </div>  
        <div className="">
          <img style={{width:"15px" ,backgroundColor:"gray" }} src="/assets/parbel12.png"/> 
          <span style={{fontSize:"12px", margin:"10px" }}>XL seat</span>
        </div> 
        <div className="">
          <img style={{width:"15px",height:'16px' ,backgroundColor:"#d900c3" }} src="/assets/zz.png"/> 
          <span style={{fontSize:"12px", margin:"10px" }}>Not available</span>
        </div> 
        </div>  
        
        <div >
          <div className="row">
            <div className="col-12 col-xl-11">
            <AirplaneBody flight={flight} labels={labels} selectedSeat={allSeatsSelected}  onSelectSeat={handleSelectSeat} /> 
            </div>
            <div className="col-12 col-xl-1">
            <div className="mb-5"><ChooseSeatsConformationCard allSeatsSelected={allSeatsSelected} onSelectSeat={handleSelectSeat} flight={flight}/></div>
           </div>
          {/* Conformation */}
          </div>
   
    </div>
      </div> 
      
    </div> 
    </>
  );
};  


function prepareSeatRows() {
  // Define seat images for both available and taken seats
  const availableSeatImg = "/assets/blue2.png"; // Assuming the assets folder is in the public directory
  const takenSeatImg = "/assets/parbel12.png"; // Add a leading slash to ensure the path is absolute

  // Assume you have 30 rows and 8 seats per row (A to K, skipping I)
  const rows = [...Array(30)].map((_, rowIndex) => {
    // Split the seats into left (A-D), middle (E-F), and right (G-K)
    const left = [...Array(4)].map((_, index) => createSeatData(rowIndex, index, availableSeatImg, takenSeatImg));
   
    const right = [...Array(4)].map((_, index) => createSeatData(rowIndex, index + 6, availableSeatImg, takenSeatImg, index === 2)); // Skipping 'I'

    return {
      left, 
     
      right
    };
  });

  return rows;
}

function createSeatData(rowIndex, seatIndex, availableSeatImg, takenSeatImg, skip = false) {
  const isOccupied = Math.random() < 0.5;
  // Skipping 'I', so if index is 6 or higher, increment by 1 to skip 'I' (8 -> 'J')
  const seatLetter = String.fromCharCode(65 + seatIndex + (skip ? 1 : 0));
  const seatNumber = `${rowIndex + 1}${seatLetter}`;
  const seatImage = isOccupied ? takenSeatImg : availableSeatImg;

  return {
    number: seatNumber,
    isOccupied: isOccupied,
    image: seatImage,
  };
}







export default Seatreservation;
