import React from 'react';
import './FlightInfo.css'; // Make sure to create a corresponding CSS file
import { useSelector } from 'react-redux';

function FlightInfo({ from, to, date, passengers, flightNumber }) {
  const {translations}=useSelector((state)=>state.languageReducer)

  return (
    <div className="flight-info p-2">
      
      <div className="flight-details d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-around"> 
      <div className="flight-route">
        <span className="airport-code">{from}</span>
        <span style={{margin:"10px"}}> → </span>
        <span className="airport-code">{to}</span>
      </div> 
      <div class="h-line d-sm-none"></div>
      <div class="vertical-line d-none d-sm-block"></div>

        <span  className="flight-date"> <img style={{margin:"10px"}} src='assets\calendar.png'/>{date}</span> 
       
       {passengers&&(
        <>
          <div class="h-line d-sm-none"></div>
          <div class="vertical-line d-none d-sm-block"></div>
          <span className="flight-passengers"> <img style={{margin:"10px"}} src='assets\users.png'/>{passengers} {translations.passenger}</span> 
        </>
       )}
        <div class="h-line d-sm-none"></div>
          <div class="vertical-line d-none d-sm-block"></div>
        <span className="flight-number"> <img style={{margin:"10px"}} src='assets\plane.png'/>{flightNumber}</span>
      </div>
    </div>
  );
}

export default FlightInfo;
