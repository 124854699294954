import React from "react";
import "./SignIn.css";
import FormSignIn from "../../components/FormSignIn/FormSignIn";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const SignIn = () => { 
  const {translations,lang}=useSelector((state)=>state.languageReducer)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    // <div className="container my-5">
    //   <div className="row nn">
    //     <div className="col xx">
    //       <div className="flight">
    //         <img src="assets/signIn.png" alt="Sign In" />
    //       </div>
    //       <p className="flight-info">
    //         Please note that the prices for your selected flights may vary
    //         depending on the combination of flights and dates you choose. The
    //         price you see displayed includes the total cost for all passengers,
    //         which covers the fare, taxes, fees, and surcharges. Please keep in
    //         mind that additional charges may apply for flights operated by other
    //         airlines. <br /> <br /> Please also be aware that the availability
    //         of Qsuite in Business Class is subject to change based on
    //         operational requirements, which may result in aircraft changes.
    //       </p>
    //     </div>
    //     <div className="col">
    //       <FormSignIn />
    //     </div>
    //   </div>
    // </div>
    <div className=" login-background py-5">
        <div className="container  py-5">
            <div className="bg-white mx-1 align-items-center row login-container">
              <div className="login-right-background col-12 col-md-7">
                <div className="cover-login">
                <h2>WELCOME BACK</h2>
                <p >
                {translations.Please_note_login}
            
             {/* <br /> <br /> Please also be aware that the availability
             of Qsuite in Business Class is subject to change based on
             operational requirements, which may result in aircraft changes. */}
           </p>
                </div>
               
            
            
              </div>
              <div className="col-12 col-md-5">
              <FormSignIn />
              </div>
            </div>
        </div>
    </div>
  );
};

export default SignIn;

