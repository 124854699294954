import React from 'react' 
import "./Footer.css"
import { Link, useLocation } from 'react-router-dom'
import { getDomainName } from '../Api/GetBaseUrl';
import { useSelector } from 'react-redux';

const Footer = ({setting}) => {
  const {translations,lang}=useSelector((state)=>state.languageReducer)
  const location = useLocation();
  const domainName=getDomainName();
  return ( 
    
  <>
 
    <div className='bg-white  background-wave pt-5 pb-3'style={{backgroundImage:"url('/assets/wave.svg')"}} > 
      <div  className='container  d-flex flex-column gap-4 flex-md-row align-items-center align-items-md-start justify-content-between'>
      
      <div  className='text-footer d-flex flex-column gap-2'>
      <Link class="navbar-brand " to="/">
      <img src={`${domainName}/${setting.footer_logo}`} height={60}  alt="Logo" loading="lazy" />
    </Link>
    <div style={{width:'280px',fontSize:'18px'}}>
      {setting.footer_title}
    </div>
      </div>
      <ul class="footer-text-list gap-1 align-items-center justify-content-center gap-lg-5">
        <div className='d-flex flex-column  gap-3 '>
          <div className=' fw-bold' style={{fontSize:'20px',color:'#273771'}}>{translations.QuickLinks}</div>
        <li class="">
          <Link class={`footer-link  ${location.pathname==='/'&&'active fw-bold'}`} aria-current="page" to="/">{translations.home}</Link>
        </li>
        <li class="">
          <Link class={`footer-link ${location.pathname==='/about'&&'active fw-bold'}`} to="/about">{translations.about}</Link>
        </li>
        <li class="">
          <Link class={`footer-link ${location.pathname==='/terms'&&'active fw-bold'}`} to="/terms">{translations.terms}</Link>
        </li>
        </div>
      
      
      </ul>
      
       <div >
        <div className='d-flex flex-column align-items-center align-items-md-start gap-3 mb-4' style={{color:'#292929'}}>
        
        <div className=' fw-bold' style={{fontSize:'20px',color:'#273771'}}>{translations.CustomerCare}</div>

           <div className='d-flex  align-items-center align-items-md-start gap-2'>
           <i class="fa-solid fa-envelope" style={{fontSize:'20px' }}></i>
           {setting.email}
           </div>
           <div className='d-flex align-items-center gap-2'>
           <i class="fa-solid fa-phone" style={{fontSize:'20px' }}></i>  
             {setting.phone_number}
           </div>
           <div className='d-flex align-items-center gap-2'>
           <i class="fa-solid fa-phone" style={{fontSize:'20px' }}></i>  
             {setting.whatsapp_number}
           </div>
        </div>
        <div  className='text-footer d-flex gap-2'>
       {setting.facebook_link&&(
       <Link to={setting.facebook_link} className='footer-icon'><i class=" fa-brands fa-facebook-f"></i></Link>
       )}
       {setting.instagram_link&&(
       <Link to={setting.instagram_link} className='footer-icon'><i class=" fa-brands fa-instagram"></i></Link>
       )}
       {setting.linkedin_link&&(
       <Link to={setting.linkedin_link} className='footer-icon'><i class=" fa-brands fa-linkedin-in"></i></Link>
       )}
       {setting.twitter_link&&(
       <Link to={setting.twitter_link} className='footer-icon'><i class=" fa-brands fa-x-twitter"></i></Link>
       )}
       {/* {setting.youtube_link&&(
       <Link to={setting.youtube_link} className='footer-icon'><i class="fa-brands fa-youtube"></i></Link>
       )} */}
      </div>
       </div>
      </div>
    
    </div> 
    <div style={{backgroundColor:'#292929',color:'#FACE3E'}} className='d-flex py-2 justify-content-center'>
       © 2024. All rights reserved. Nesma Airlines
    </div>
  </>
    
  )
}

export default Footer