import React, { useEffect, useState } from "react";
import FetchData from "../../components/Api/FetchData"; // Make sure to import FetchData 
import { getDomainName } from "../../components/Api/GetBaseUrl";

const Privacy = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const Url = "/privacy"; // Assuming this is the correct endpoint for privacy policy
      const token = localStorage.getItem("token");
      const locale = "en";

      setLoading(true);
      const result = await FetchData(Url, token, locale);
      if (!result.error) {
        setData(result);
      } else {
        console.error("Error fetching data:", result.error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const domainName = getDomainName();
  const imagePath = data ? `${domainName}/${data.image}` : '';

  return (
    <>
      {loading && <div>Loading...</div>}
      {!loading && data && (
        <div className="container">
          <div className="row m-b-50">
            <div className="col-md-2" />
            <div className="col-md-8 col-xs-12 animated fadeInLeft p-t-20">
              <div className="section-name m-t-150 m-b-20 m-res py-5">
                <span className="desc-text" style={{fontSize:"20px"}}>{data.title}</span>
                <br /> 
                <br />
              </div>
              {imagePath && <img style={{width:"50%"}} src={imagePath} alt="Privacy Policy" />}
              <div className="main-p" dangerouslySetInnerHTML={{ __html: data.description }} />
            </div>
            <div className="col-md-2" />
          </div>
        </div>
      )}
    </>
  );
};

export default Privacy;

  
  
