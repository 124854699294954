import React, { useEffect, useState } from 'react'; 
import { useNavigate } from 'react-router-dom';
import Total from '../components/common/Total';
import Counter from '../components/common/Counter';
import { useSelector } from 'react-redux';

export const Cart = () => {
  const {translations,lang}=useSelector((state)=>state.languageReducer)
  const [cartItems, setCartItems] = useState([]);
  const [seatsItems, setSeatsItems] = useState([]); 
  const [total,setTotal] =useState(""); 
  const navigate = useNavigate();

  const handleCheckout = () => {
    navigate('/PassngerData');  // Change '/checkout' to your desired path
  };
  const goToSeats=()=>{
    navigate('/10/Seatreservation')
  }
  const handleCounterChange = (name, value) => {
    console.log('name',name)
    console.log('value',value)
    const serveces=[];
    cartItems.map((servece)=>{
       if(servece.id===name){
        serveces.push({...servece,count:value})
       }else{
        serveces.push(servece)
       }
       
    }) 
    setCartItems(serveces)
    localStorage.setItem('serveces',JSON.stringify(serveces))
    // const temp=[...tempServices];
    // tempServices.map((tempService,idx)=>{
    //    if(name==tempService.id){
    //     temp[idx]={...tempService,count:value}
    //    }
    // })
    // setTempServices(temp);
  }
  useEffect(() => {
    const fetchDataFromLocalStorage = () => {
      const storedData = localStorage.getItem('serveces');
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        setCartItems(parsedData);
      }
      let storedSeats = localStorage.getItem('seats');
      if (storedSeats) {
        storedSeats = JSON.parse(storedSeats);
        setSeatsItems(storedSeats);
      }
    };

    fetchDataFromLocalStorage();
  }, []);
  useEffect(() => { 
    let t=0;

       cartItems?.map((servece)=>{
        t+=servece.price*servece.count
       })
      
       seatsItems?.map((s)=>{
        t+=parseFloat(s.seat.price)
       })
      
    
     setTotal(t)
 }, [seatsItems,cartItems]);
  
  const removeFromCart = (indexToRemove) => {
    const updatedCart = cartItems.filter((item, index) => index !== indexToRemove);
    setCartItems(updatedCart);
    localStorage.setItem('serveces', JSON.stringify(updatedCart));
  };
  const removeFromSeatCart = (indexToRemove) => {
    const updatedCart = seatsItems.filter((item, index) => index !== indexToRemove);
    setSeatsItems(updatedCart);
    localStorage.setItem('seats', JSON.stringify(updatedCart));
  };
  
  return (
  
  //   <div className='Cover'>    
    
  //   <div className='container '>
   
  
   
  
  //  <div className="d-flex justify-content-center align-items-start" >
  //  <div>
  //      <div className="my-5">
  //       <div className="d-flex flex-column flex-md-row gap-3 justify-content-center text-center text-md-start align-items-center">
      
  //          <div className="d-flex flex-column gap-2 ">
  //          <h1 className='text-white fw-bold '>  {translations.Payment_summary}</h1> 
          
        
  //          </div>
  //       </div>
        
        
    
         
       
  //   <div className="text-center d-flex flex-column align-items-between justify-content-between" style={{ width: "900px", backgroundColor: "#f8f9fa", padding: "20px", margin: "20px 0",borderRadius:'30px' }}>
     
      
        
  //         {seatsItems.map((item, index) => (
  //             <div key={index} className=" d-flex flex-column flex-lg-row justify-content-between align-items-center">
  //               <div>
  //                 {/* <h5 className="mb-1 text-start">{item.title}</h5> */}
  //                 <div className="city d-flex gap-2 align-items-center">
  //                   <img src='/assets/green2.png'  style={{width:"20px" ,height:"20px" }}/> 
  //                   <div className='d-flex gap-1 align-items-center'>
  //                     <p className='m-0'>{item.rowNumber}</p>
  //                     <p className='m-0'>{item.seatLabel} </p>
  //                   </div>
                    
  //                   </div>

  //                 <p className="mb-1 text-start"> Price :${item.seat.price}</p>
  //                 {/* <p className="mb-0 text-start">Quantity :{item.count}</p> */}
  //               </div>
  //               <button onClick={() => removeFromSeatCart(index)} className="btn btn-sm">Remove</button>
  //             </div>
  //           ))}
  //           {cartItems.map((item, index) => (
  //                   <div className='row align-items-center mb-4'>
  //                   <div className='col-md-7 '>
  //                   <div className='d-flex flex-column gap-2 align-items-center align-items-md-start'style={{width:'90%'}}>
  //                        <h4 className='fw-bold' style={{color:'#292929'}}>{item.title}</h4>
  //                         <p className=' m-0' style={{color:'#292929'}}><span style={{color:'#273771'}} className='me-2 '>{translations.Price}</span> {item.price} {translations.EGP}</p>
  //                     </div>
  //                   </div>
  //                   <div className='col-md-3'>
  //                   <div className='d-flex gap-5 my-3 align-items-center justify-content-center'>
  //                    <Counter
  //                        name={item.id}
  //                        defaultValue={item.count}
  //                       onCounterChange={handleCounterChange}
  //                     />
  //                     </div>
  //                   </div>
  //                   <div className='col-md-2'>
  //                   <div className='d-flex flex-column gap-3 align-items-center align-items-lg-end'>
  //                     <div style={{color:"#292929",fontSize:'18px'}}><span className='me-1' style={{color:'#273771'}}>{translations.EGP}</span> {item.price*item.count}  </div>
  //                   </div>
  //                   </div>
  //               </div>
  //           ))}
       
  
 
  //   </div>
             
            
         
      
     
         
         
         
        
         
  //      </div>
  //    </div>
  // </div>
  //   </div>
  //  </div> 

  <div className='Cover'> 
  <div >
  <div className='container' >    

<h1 className='FLYWITHUS pt-5 text-white fw-bold'>{translations.Payment_summary}</h1> 
<div className='d-flex justify-content-center mt-5'>
  <div className=" mb-4  d-flex flex-column gap-3 " style={{ width: "1200px",borderRadius:'30px', backgroundColor: "white", padding: "20px" }}>
           {seatsItems.map((item, index) => (
              <div key={index} className=" d-flex flex-column flex-lg-row justify-content-between align-items-center">
                <div>
                  <div className="city d-flex gap-2 align-items-center">
                  <div className='d-flex gap-2'>
          <p className="my-1 text-start" style={{color:"#FACE3E"}}> seat {index+1} : </p>
          <p  className="my-1 fw-bold" style={{color:"#273771"}}>{item.rowNumber}{item.seatLabel}</p>
         </div>
        

                    
                     </div>

                 <p className="mb-1 text-start"> Price : <span className="text-muted my-1"><span className='me-1' style={{color:"#FDECB6"}}>{translations.EGP}</span> {item.seat.price}</span></p>
                 {/* <p className="mb-0 text-start">Quantity :{item.count}</p> */}
              </div>
               <div className='d-flex gap-2 align-items-center justify-content-md-between'>
               <div className='pen-fancy d-flex justify-content-center '>
                  <i class="fa-solid fa-pen-fancy"  onClick={goToSeats} ></i>
               </div>
               <div className=' trash-delete me-2 d-flex  '>
                  <i class="fa-regular fa-trash-can"  onClick={() => removeFromSeatCart(index)} ></i>
               </div>
          
         
               </div>
              </div>
           ))}
          {cartItems.map((item, index) => (
                    <div className='row align-items-center mb-4'>
                    <div className='col-md-6 '>
                    <div className='d-flex flex-column gap-2 align-items-center align-items-md-start'style={{width:'90%'}}>
                         <h4 className='fw-bold' style={{color:'#292929'}}>{item.title}</h4>
                          <p className=' m-0' style={{color:'#292929'}}><span style={{color:'#273771'}} className='me-2 '>{translations.Price}</span> {item.price} {translations.EGP}</p>
                      </div>
                    </div>
                    <div className='col-md-3'>
                    <div className='d-flex gap-5 my-3 align-items-center justify-content-center'>
                     <Counter
                         name={item.id}
                         defaultValue={item.count}
                        onCounterChange={handleCounterChange}
                       />
                      </div>
                    </div>
                    <div className='col-md-2'>
                   <div className='d-flex flex-column gap-3 align-items-center align-items-lg-end'>
                       <div style={{color:"#292929",fontSize:'18px'}}><span className='me-1' style={{color:'#273771'}}>{translations.EGP}</span> {item.price*item.count}  </div>
                    </div>
               </div>
               <div className='col-md-1 trash-delete d-flex justify-content-center justify-content-lg-end'>
                  <i class="fa-regular fa-trash-can"  onClick={() => removeFromCart(index)} ></i>
               </div>
               </div>
            ))}
 {total?(
  <>
              <hr/> 
<div className='text-end'>
  
<div className='d-flex flex-column  flex-sm-row justify-content-between align-items-center px-3'>
 <div className='d-flex flex-column gap-2'>
 <span style={{fontSize:"16px"}} className=' me-4  '>{translations.Total}</span>
 <span style={{fontSize:"14px"}} className=''>{translations.EGP} <span style={{fontSize:"20px",fontWeight:'bold',color:'#292929'}} className='ms-1'>{total}</span></span>
 <div className='text-end'>
</div> 
</div> 

<button   style={{width:'150px',margin:'0'}} onClick={handleCheckout}  className='Login-button'>
 {/* {services[0].category.id===11?translations.Save_Meal:translations.Save_Services} */}
  {translations.checkout}
 </button>
 </div>
</div>
  </>
 ):(
  <div className='text-center'>{translations.Please_select}</div>
 )}
  </div>
 
</div>

</div> 
  </div>
</div> 
  );
};



