import React from 'react' 
import "./OverviewCheckout.css"
import CheckoutForm from '../../components/CheckoutForm/CheckoutForm'
import PaymentForm from '../../components/PaymentForm/PaymentForm';  
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const OverviewCheckout = () => { 
  const {translations}=useSelector((state)=>state.languageReducer)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    
    <div className='Cover'> 
    <div className=' container'>    
    
    <h1 className='FLYWITHUS pt-5 text-white fw-bold'>{translations.FLY}</h1> 
    <p style={{color:'#D6D9E4'}}>{translations.DISCOVER} </p>
     <CheckoutForm/>
   
    </div> 
    </div> 
    
    
   
  
  
  )
}

export default OverviewCheckout