import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./FormSignIn.css";
import PostData from '../Api/PostData';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

const FormSignIn = () => {
  const {translations,lang}=useSelector((state)=>state.languageReducer)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    const loginUrl = 'login';
    const postData = {
      email,
      password,
    };
    
    try {
      const response = await PostData(loginUrl, postData);
      console.log('Login response:', response);
      
      if (response.status === 200) {
        Cookies.set('myCookie', response.data.token);
        let location=localStorage.getItem('location');
        if(location){
          location=JSON.parse(location);
          navigate(location);
        }else{
          navigate('/');
        }
        
          
       
        
      } else {
        
        alert('Login failed: Email or Password wrong ');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Login failed: ' + error.message);
    }
  };

  return (
    <form className='' style={{ width: "100%",height:'100%', padding: "30px" }} onSubmit={handleLogin}>
      <div className='d-flex flex-column align-items-center justify-content-end gap-3'>
      <h5 style={{color:'#273771',fontWeight:'bold',fontSize:'26px'}}>{translations.LOGIN}</h5>
      <input
        type="email"
        className="form-control"
        value={email}
        style={{borderRadius:'20px'}}
        onChange={(e) => setEmail(e.target.value)}
        placeholder={translations.enter_email}
        required
      />
     <div>
     {/* <i class="fa-solid fa-user"></i> */}
     </div>
      <input
        type="password"
        className="form-control "
        style={{borderRadius:'20px'}}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder={translations.enter_password}
        required
      />
      <button type="submit" className='Login-button'>{translations.Login}</button>
      </div>
    </form>
  );
};

export default FormSignIn;





