import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import "./FormPassengerinfo.css"; 
import { useSelector } from 'react-redux';



const FormPassengerinfo = () => { 
  const {translations}=useSelector((state)=>state.languageReducer)
  const [error,setError]=useState("")
  const navigate = useNavigate();
  const [form,setForm]=useState({
    gender:"Male",
    first:"",
    last:"",
    email:"",
    mobile:""
  });
  const handelForm=(e)=>{
    setForm({...form,[e.target.name]:e.target.value})
  }
  const Handlesave=(e)=>{
    e.preventDefault();
    if(form.mobile.match('[0-9]{10}')){
      localStorage.setItem("user",JSON.stringify(form));
      navigate('/OverviewCheckout')
    }else{
      setError('Invalid Mobile Number')
    }
  }
  useEffect(()=>{
    setError("")
   },[form])   
  return ( 
   <form onSubmit={(e)=>Handlesave(e)} className='bg-white PassengerInfo mb-4' style={{padding:"30px"}}>
    <h3 className='mb-4 fw-bold' style={{color:'#273771'}}> {translations.Passenger_info}</h3>
 <div className='row  mb-4 '> 
 <label style={{color:"gray" ,marginBottom:"10px"}}>{translations.Gender}</label>

   <div className="px-3">
   <select className="form-select " 
   value={form.gender}
   required
   onChange={(e)=>handelForm(e)}
   style={{borderRadius:'8px'}} aria-label="Default select example" name='gender'> 
  <option value="Male">{translations.Male}</option>
  <option value="Female">{translations.Female}</option>
</select>
     
    </div>     

 </div>
  <div className="row mb-4"> 
    <div className="col">
      <div data-mdb-input-init className="form-outline"> 
        <label style={{color:"gray" ,marginBottom:"10px"}}>{translations.First_name}</label>


<input type="text" id="form6Example1" className="form-control "
    name='first' 
    style={{borderRadius:'8px'}}
    value={form.first}
    required
    onChange={(e)=>handelForm(e)}
    placeholder={translations.First_name}/>

  
  
      </div>
    </div>
    <div className="col">
      <div data-mdb-input-init className="form-outline"> 
        <label style={{color:"gray" ,marginBottom:"10px"}}>{translations.Last_name}</label>


        <input type="text" 
        id="form6Example2" style={{borderRadius:'8px'}}
         className="form-control"  
         name='last' 
         value={form.last}
         required
         onChange={(e)=>handelForm(e)}
         placeholder={translations.Last_name}/>

      </div>
    </div>
  </div>

  <div data-mdb-input-init className="form-outline mb-4"> 
    <label style={{color:"gray" ,marginBottom:"10px"}}>{translations.Email}</label>


    <input type="email" id="form6Example5" 
     name='email' 
     value={form.email}
     required
     onChange={(e)=>handelForm(e)}
    style={{borderRadius:'8px'}} className="form-control" placeholder={translations.Email}/>
     
  </div> 

  <div data-mdb-input-init className="form-outline mb-4">
  <label style={{color:"gray" ,marginBottom:"10px"}}>{translations.Mobile_number}</label>
    <input type="text"  className="form-control"
     name='mobile' 
     value={form.mobile}
     required
     onChange={(e)=>handelForm(e)}
     style={{borderRadius:'8px'}} placeholder='+20  165431931' />
   <div className='text-danger'>{error}</div>
  </div>
 
 
  {/* <div className="form-check d-flex justify-content-end mb-4">
  <div className='text-end'>
<img src='assets\plus.png' style={{width:"15px"}}></img>
      <Link to="" className="getActionCard">Add Passenger</Link> 
      </div>
  </div>  */}

  <div className='d-flex justify-content-end'>
  <button type='submit' className="Login-button py-2  my-3 rounded-5 d-flex justify-content-center" style={{textDecoration:'none',width:'250px'}}>
    {translations.Save_and_continue}
  </button>
</div>

</form>




  )
}

export default FormPassengerinfo