import React, { useEffect, useState } from 'react' ; 
import { Link, useNavigate } from 'react-router-dom'; 
import "./AdditionalBaggageCard.css"
import { getDomainName } from '../Api/GetBaseUrl';
import Counter from '../common/Counter';
import { useSelector } from 'react-redux';


const AdditionalBaggageCard = ({services}) => {
  const {translations,lang}=useSelector((state)=>state.languageReducer)
  const navigate = useNavigate();
  const domainName=getDomainName();
  const [total,setTotal]=useState(0);
  const [error,setError]=useState("");
  const [tempServices,setTempServices]=useState([]);
  const handleCounterChange = (name, value) => {
    console.log('name',name)
    console.log('value',value)
    const temp=[...tempServices];
    tempServices.map((tempService,idx)=>{
       if(name==tempService.id){
        temp[idx]={...tempService,count:value}
       }
    })
    setTempServices(temp);
  }
  const addServeses=()=>{
    const servs=tempServices.filter((service)=>service.count>0);
      let local=localStorage.getItem('serveces');
      if(local && local!=="[]"){
        local=JSON.parse(local);
        const allServeces=[];
        local.map((serv)=>{
          const help=tempServices.filter((h)=>h.id===serv.id)
          if(help.length>0){
            if(help[0].count>0){
                allServeces.push(help[0])
              }

          }else{
            allServeces.push(serv)
          }
         
        })
        servs.map((serv)=>{
          const help=local.filter((h)=>h.id===serv.id)
          if(help.length>0){
          }else{
            allServeces.push(serv)
          }
         
        })

        localStorage.setItem('serveces',JSON.stringify(allServeces))
      }else{
        localStorage.setItem('serveces',JSON.stringify(servs))
      }
      
      navigate("/");

      

    
   
  }
  useEffect(()=>{
    let locals=localStorage.getItem('serveces');
    const servs=[]
    if(locals){
      locals=JSON.parse(locals);
      services.map((service)=>{
        const help=locals.filter((local)=>local.id===service.id)
        if(help.length>0){
          servs.push(help[0])
        }else{
          servs.push({id:service.id,title:service.title,description:service.description,image:service.image,price:service.price,count:0,category_id:service.category.id})
        }

      })

    }else{
      services.map((service)=>{
        servs.push({id:service.id,title:service.title,description:service.description,image:service.image,price:service.price,count:0,category_id:service.category.id})
      })
    }
    setTempServices(servs);    
  },[services])
  useEffect(()=>{
    setError("")
    let t=0;
    tempServices.map((seleced)=>{
      t+=seleced.price*seleced.count;
    })
    setTotal(t)
  },[tempServices])
  return (
    <div className='w-100 bg-white p-3 mt-4' style={{borderRadius:'30px'}}>
    <div className=''>
     
     <div className='continar text-center mt-2' > 
     {services[0].category.title==="MEALS ON BOARD"||services[0].category.id===11?(
 <div className='row continar ' style={{width:'90%',margin:'auto'}} >
    
 {tempServices.map((meal,idx)=>(
     <div className=' col-lg-6   mb-5' >
       {meal.image&&(
     <img className='imgs' style={{height:'300px',width:'100%'}} src={`${domainName}/${meal.image}`}/> 
       )}

<div className=' d-flex justify-content-end'>
         <div className='position-relative  message-container'>
         <i className="fa-regular fa-circle-question  message-container" style={{ margin: "5px"}}>
        </i> 
      <div className='message-show-details'>
         {meal.description}
        </div>
         </div>
      </div>
     <div className='d-flex flex-column flex-sm-row mt-3   justify-content-between align-items-center ' style={{width:'90%', margin:'auto'}}>
          
     <div className='d-flex gap-1 align-items-center mb-4 me-3'>
       {/* <input type='radio' style={{width:'20px',height:'20px'}}/>  */}
     <p className='m-0 fw-bold' style={{fontSize:'18px'}}>{meal.title}</p>
     </div>
     <div className='d-flex gap-3 justify-content-between align-items-center mb-4'>
     <div className='' style={{color:"gray",fontSize:'18px'}}><span style={{color:'#273771'}} className='me-2'>{translations.Price}</span>  {meal.price} {translations.EGP} </div>
     <Counter
       name={meal.id}
       defaultValue={meal.count}
       onCounterChange={handleCounterChange}
     />
     </div>
     </div>
   
     <span > <span className='EGP'></span></span>
     </div>
 ))} 

</div>  
     ):(
      <div className='d-flex flex-column gap-4 continar '  >
       
       <div className='d-flex flex-column gap-5 mt-4' >
       {tempServices.map((meal,idx)=>(
//           <div className=' d-flex flex-column flex-lg-row  justify-content-between' >
//           <div className='d-flex flex-column gap-2 align-items-start align-items-lg-start'style={{width:'90%'}}>
//             <h4>{meal.title}</h4>
//             {/* <p className='fw-bold m-0 ' style={{color:'gray'}}>{meal.description}</p> */}
//             <p className='fw-bold m-0' style={{color:'black'}}><span style={{color:'#273771'}} className='me-2'>{translations.Price}</span> {meal.price} {translations.EGP}</p>
//           </div>
//           <div className='d-flex gap-5 align-items-center'>
//           <div className='d-flex gap-5 align-items-center'>

// <Counter
//   name={meal.id}
//   defaultValue={meal.count}
//   onCounterChange={handleCounterChange}
// />
// </div>
// <div className='d-flex gap-5 align-items-center'>
// <div className='d-flex flex-column gap-3 align-items-center align-items-lg-end'>
// <div style={{color:"black",fontSize:'18px',fontWeight:'bold'}}><span className='me-2' style={{color:'#273771'}}>{translations.Unite_Price}</span> {meal.price*meal.count}  </div>

// </div>

// </div>
//           </div>
         
//           </div>
        <div className='row align-items-center '>
            <div className='col-md-8 '>
            <div className='d-flex flex-column gap-2 align-items-center align-items-md-start'style={{width:'90%'}}>
                 <h4 className='fw-bold' style={{color:'#292929'}}>{meal.title}</h4>
                 <p className=' m-0 ' style={{color:'gray',textAlign:'start'}}>{meal.description}</p>
                  <p className=' m-0' style={{color:'#292929'}}><span style={{color:'#273771'}} className='me-2 '>{translations.Price}</span> {meal.price} {translations.EGP}</p>
              </div>
            </div>
            <div className='col-md-3'>
            <div className='d-flex gap-5 my-3 align-items-center justify-content-center'>
             <Counter
                 name={meal.id}
                 defaultValue={meal.count}
                onCounterChange={handleCounterChange}
              />
              </div>
            </div>
            <div className='col-md-1'>
            <div className='d-flex flex-column gap-3 align-items-center align-items-lg-end'>
              <div style={{color:"#292929",fontSize:'18px'}}><span className='me-1' style={{color:'#273771'}}>{translations.EGP}</span> {meal.price*meal.count}  </div>
            </div>
            </div>
        </div>
      ))} 
       </div>
     
     
     </div>  
     )}
    
     <br/>
   

     <hr/> 

     <div className='text-end py-2'>
       
     <div className='d-flex flex-column gap-3 flex-sm-row justify-content-between align-items-center px-3'>
      <div>
      <span style={{fontSize:"20px"}} className=' me-4  '>{translations.Total}</span>
      <span style={{fontSize:"16px"}} className=''>{translations.EGP} <span style={{fontSize:"20px",fontWeight:'bold',color:'#292929'}} className='ms-1'>{total}</span></span>
      <div className='text-end'>
      <span style={{fontSize:"16px" , color:'red'}} className=''>{error}</span>
     </div> 
     </div> 
    
     <button  onClick={addServeses} style={{width:'150px',margin:'0'}}  className='Login-button'>
      {/* {services[0].category.id===11?translations.Save_Meal:translations.Save_Services} */}
       {translations.Confirm}
      </button>
      </div>
     </div>
     </div>
    </div>
  </div>
    // <div>
    //   <div className='container-fluid  big2'>
    //    <div className='continar text-center'> 
    //    <div className='row'>
    //     <div className='col-6 meal-option image-wrapper'>
    //         <img className='imgs' src={`${domainName}/${service.image}`}/>  
           
    //        <h5 style={{marginTop:"40px"}} className='text-start'>{service.title}</h5>
         
      
           
    //     </div>  

        
       
    //    </div>   

    //    <div  className="d-flex justify-content-between lh-condensed">
           
    //        <div>
    //          <p className="text" style={{fontSize:"12px" ,fontWeight:"600"}}>Bigger than baggage allowance (over 158 cm* and max.23 kg)</p>
             
    //        </div> 
    //        <span style={{fontSize:"13px",fontWeight:"600"}} className="text-muted"> <input type="radio" /> {service.price} EGP <input style={{width:"40px"}} type='number'></input></span>
          
    //      </div> 

    //      <div  className="d-flex justify-content-between lh-condensed">
           
    //        <div>
    //          <p className="text" style={{fontSize:"12px" ,fontWeight:"600"}}>No baggage </p>
             
    //        </div> 
    //        <span style={{fontSize:"13px",fontWeight:"600"}} className="text-muted"> <input type="radio" /> 0 EGP <input style={{width:"40px"}} type='number'></input></span>
          
    //      </div>  
    //      <div  className="d-flex justify-content-between lh-condensed">
           
    //        <div>
    //          <h6 >Oversized baggage</h6>
             
    //        </div> 
           
    //     <div className='text-end'>
    //       <img src='assets/plus.png' style={{width: "15px"}} alt="Plus Icon" />
    //       <Link to="AdditionalBaggage" className="getActionCard">Add additional baggage</Link> 
    //     </div>
          
    //      </div> 
         
       
        

    //    <hr/> 

    //    <div className='text-end py-2'>
    //     <span style={{fontSize:"20px"}} className='EGP'> 1250 EGP</span>
    //    </div> 
    //    <div className='text-end'>
    //     <span style={{fontSize:"15px" ,}} className='EGP'> .Total price for baggage</span>
    //    </div> 
    //    <div className='d-flex justify-content-end'> 
    //    <Link to="/" className='btn'>Add baggage</Link>
       
    //    </div>
    //    </div>
    //   </div>
    // </div>
  )
}

export default AdditionalBaggageCard
