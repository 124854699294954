import React, { useEffect, useState } from 'react' 
import FlightInfo from '../FlightInfo/FlightInfo'
import FlightTimeSlider from '../FlightTimeSlider/FlightTimeSlider';
import "./Reseat.css"
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

const Reseat = () => {
  const {translations}=useSelector((state)=>state.languageReducer)
  const [flight,setFlight]=useState({});
  const [reservation,setReservation]=useState({});
  useEffect(()=>{
    let flight=localStorage.getItem('flight');
    if(flight){
      flight=JSON.parse(flight);
      setFlight(flight)
      console.log('flight',flight)
    }
    let reservation=localStorage.getItem('reservation');
      if(reservation){
        reservation=JSON.parse(reservation);
        setReservation(reservation)
      }
  },[])
  return (
    <div className='d-flex justify-content-center'>
      <div className=" text-center mb-5 d-flex layout-wrapper" style={{ backgroundColor:"white" ,padding:"20px",borderRadius:'30px' }}> 
  <div className="row"> 
  
    <h2> <img  src='assets\succ.png'/>  {translations.Successful}</h2> 
    <p style={{fontSize:"14px" ,color:"#4A5970"}}> {translations.Successful_reservation}Successful reservation check your mail </p>
     
    <div className="col">  
    <div className='nn'>
    <FlightInfo 
     from={flight?.dep_port}
     to={flight?.arr_port} 
     date={flight.departure_date&&format(new Date(flight.departure_date), "dd,MMM yyyy")} 
     passengers={reservation?.seats?.length} 
     flightNumber={flight.flight_no}
/>
{/* <FlightTimeSlider
        departureTime="20:05"
        arrivalTime="06:45"
        duration="1 Stop, 6h 25m"
      />  */}
     </div>
      <div>
      <h4 className='fw-bold mb-4' style={{color:'#FACE3E'}}>{translations.Overview}</h4> 
      
      </div>
    
      <ul className="list-group mb-3">  
      {reservation?.seats?.map((seat,idx)=>(
         <li className="d-flex justify-content-between lh-condensed" key={idx}> 
        
         <div className='d-flex gap-2'>
          <p className="my-1 text-start" style={{color:"#FACE3E"}}> seat {idx+1} : </p>
          <p  className="my-1 fw-bold" style={{color:"#273771"}}>{seat.seat.row}{seat.seat.column}</p>
           <p  className="my-1 fw-bold" style={{color:"#646263"}}>{seat.seat.category.position_x} {seat.seat.category.position_y}</p>
         </div>
         <span className="text-muted my-1"><span className='me-1' style={{color:"#FDECB6"}}>{reservation?.currency}</span> {seat.price}</span>
       </li>
      ))} 
      
      <br/>
      {reservation?.services?.length>0&&(
        <h3 className='text-start fw-bold' style={{color:"#273771"}}>
       Services
      </h3>
      )}
        {reservation?.services?.map((service,idx)=>(
       <li className="my-1 row justify-content-between lh-condensed">
       <div className='col-6 '>
         <p className="my-1 fw-bold text-start" style={{color:"#646263"}}>{service.service.title}</p>
         
       </div>
       <span className="col-3 my-1 text-end" style={{color:"#292929"}}> {service.quantity} x {service.price}</span>
       <span className="col-3 my-1 text-end" style={{color:"#292929"}}><span className='me-1 text-muted' >{reservation?.currency}</span> {service.quantity*service.price}</span>
     </li>
      ))} 
     
   
      
      
        <li className="d-flex justify-content-between pt-3 mt-4" style={{borderTop:'1px solid #D6D9E4'}}>
          <h4 className='fw-bold'>Total</h4>
          <h3 className='fw-bold' style={{color:'#FACE3E'}}><span className='me-1 text-muted' >{reservation.currency}</span>{reservation.price}</h3>
        </li> 
        <br/>  
    

        <div>
        <h4 className='text-start fw-bold' style={{color:"#273771"}}>
        {translations.Passenger_info}
      </h4>
      {/* <p className='text-start'>{translations.overview_lists}</p> */}
      </div>  
      <li className="my-1 d-flex justify-content-between lh-condensed">
          <div>
            <p className="my-1 info">{translations.name} </p>
            
          </div>
          <span style={{fontSize:"13px"}} className="text-muted my-1">{reservation.name}</span>
        </li>  


        <li className="my-1 d-flex justify-content-between lh-condensed">
          <div>
            <p className="my-1 info">{translations.Email} </p>
            
          </div>
          <span style={{fontSize:"13px"}} className="text-muted my-1">{reservation.email}</span>
        </li>  
        {/* <li className="my-1 d-flex justify-content-between lh-condensed">
          <div>
            <p className="my-1 info">{translations.Successful_reservation}Country </p>
            
          </div>
          <span style={{fontSize:"13px"}} className="text-muted my-1">Egypt</span>
        </li>   */}
        <li className="my-1 d-flex justify-content-between lh-condensed">
          <div>
            <p className="my-1 info">{translations.Mobile_number} </p>
            
          </div>
          <span style={{fontSize:"13px"}} className="text-muted my-1">{reservation.phone}</span>
        </li> 
      </ul> 
      
    </div>
   
    
    
  
    </div>
    </div>
    </div>
  )
}

export default Reseat
