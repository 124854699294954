import React from 'react'
import { getDomainName } from '../Api/GetBaseUrl';

export const PageContent = ({image,title,description,page}) => {
  const imagePath = image ? `${getDomainName()}/${image}` : '';
  console.log('imagePath',imagePath)
  return (
    <div className='' style={{backgroundImage: `url(${imagePath})`,backgroundRepeat:'repeat-x',width: "100%"}}> 
      <div >
      <div className='container' >    
    
    <h1 className='FLYWITHUS pt-5 text-white fw-bold'>{page}</h1> 
    <div className='d-flex justify-content-center mt-5'>
      <div className=" mb-4  d-flex flex-column gap-5 " style={{ width: "1200px",borderRadius:'30px', backgroundColor: "white", padding: "20px" }}>
      <h3 className='fw-bold' style={{color:'#273771'}}>{title}</h3> 
      <div className="" style={{color:'#292929'}} dangerouslySetInnerHTML={{ __html:description }} />

      </div>
    </div>
   
    </div> 
      </div>
    </div> 
  )
}
